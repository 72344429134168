import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import axios from "axios";
import { APIURI } from "../config";
import createMarkup from "../utils/CreateMarkup";
import Loader from "../utils/Loader";
import { useHistory } from "react-router-dom";

export default function HintComponent() {
  const accessToken = localStorage.getItem("accesstoken");
  const sceneID = "41224d776a326fb40f000001";
  const accessCode = localStorage.getItem("accessCode");
  let config;
  if (accessCode) {
    config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        accesscode: accessCode,
      },
    };
  } else {
    config = {
      headers: { Authorization: `Bearer ${accessToken}` },
    };
  }
  const history = useHistory();
  const [load, setLoad] = useState(false);
  const [hints, setHints] = useState([]);
  const [hintDescription, setHintsDescription] = useState("");
  const [isHintAvailable, setHintAvailable] = useState(false);
  useEffect(() => {
    loadHints();
  }, []);

  const loadHints = async () => {
    if (sceneID === undefined) {
      alert("No Scene specified");
    } else {
      setLoad(true);
      await axios
        .get(`${APIURI}/web-hint/global/${sceneID}`, config)
        .then((res) => {
          setHints(res.data.message.hint);
          setHintsDescription(res.data.message.description);
          setHintAvailable(true);
          setLoad(false);
          console.log(res.data.message);
        })
        .catch((err) => {
          setLoad(false);
          // alert("No Hints found for this Scene!");
        });
    }
  };
  if (load) {
    return <Loader />;
  }
  return (
    <div className="Hints-footer" style={{ padding: "1em", paddingTop: "0" }}>
      <div
        className="Hints-container"
        // style={{ padding: "1em", paddingTop: "0" }}
      >
        <div
          className="hint-desc"
          dangerouslySetInnerHTML={createMarkup(hintDescription)}
        />
      </div>
      <div>
        {hints.map((hint, i) => {
          return (
            <Accordion key={hint._id} className="Accordion-Outer">
              <AccordionSummary
                className="accordion-title"
                expandIcon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 0 18 18"
                    width="24"
                    style={{ marginLeft: "-9px" }}
                  >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
                  </svg>
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <p className="hintTitle">{hint.title}</p>
              </AccordionSummary>
              {hint.description.length ? (
                <AccordionDetails className="accordion-details">
                  {console.log(hint.description)}
                  <div
                    dangerouslySetInnerHTML={createMarkup(hint.description)}
                  />
                </AccordionDetails>
              ) : (
                <></>
              )}

              {hint.subhint.map((subHint, index) => {
                return (
                  <Accordion className="Accordion-Inner">
                    <AccordionSummary
                      className="Accordion-SubHintTitle"
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p className="subHintTitle">{subHint.title}</p>
                    </AccordionSummary>
                    <AccordionDetails>
                      {/* <Chip label={`SUBHINT ${i + 1}.${index + 1} `} /> */}
                      <div
                        dangerouslySetInnerHTML={createMarkup(
                          subHint.description
                        )}
                      />
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Accordion>
          );
        })}
        {load ? (
          ""
        ) : (
          <div style={{ width: "100%", textAlign: "center" }}>
            <button className="tut-skip" onClick={(e) => history.goBack()}>
              Skip Tutorial
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

/* eslint-disable react/jsx-no-target-blank */
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  DialogTitle,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import axios from "axios";

import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router";
import { APIURI, SOCKET_URI } from "../config";
import createMarkup from "../utils/CreateMarkup";
import LogoutComponent from "./Logout";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Loader from "../utils/Loader";
import io from "socket.io-client";
const socketNotification = io(`${SOCKET_URI}/notification`, {
  reconnection: true, // Enable automatic reconnection
  reconnectionAttempts: 10, // Number of reconnection attempts
  reconnectionDelay: 2000, // Delay between reconnection attempts (in milliseconds)
});

function Hints(props) {
  let history = useHistory();
  const sceneID = props.match.params.sceneID;
  const [load, setLoad] = useState(false);
  const [hints, setHints] = useState([]);
  const [isHintAvailable, setHintAvailable] = useState(false);
  const [description, setDescription] = useState("");
  const [togglePopover, setTooglePopover] = useState(false);
  const [togglePopoverMessage, setTooglePopoverMessage] = useState([]);
  const [togglePopoverArr, setTooglePopoverArr] = useState([]);
  const [hintMetadata, setHintMetadata] = useState("");
  const [penaltyShow, setPenaltyShow] = useState(false);
  const [hindDescPod, setHindDescPod] = useState("");
  const [podData, setPodData] = useState([]);
  const [hintsLockUnlockStatus, setHintsLockUnlockStatus] = useState([]);
  const [subHintsLockUnlockStatus, setSubHintsLockUnlockStatus] = useState([]);
  const [penaltyErrorModal, setPenaltyErrorModal] = useState(false);
  const [pushNotificationModal, setPushNotificationModal] = useState(false);
  const [pushNotificationData, setPushNotificationData] = useState("");
  const [podNotStarted, setPodNotStarted] = useState(false);
  const [podNotificationsOnTime, setPodNotificationsOnTime] = useState([]);
  const [podId, setPodId] = useState("");

  const accessToken = localStorage.getItem("accesstoken");
  const accessCode = localStorage.getItem("accessCode");
  let config;
  if (accessCode) {
    config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        accesscode: accessCode,
      },
    };
  } else {
    config = {
      headers: { Authorization: `Bearer ${accessToken}` },
    };
  }
  localStorage.setItem("redirect_url", window.location.href);

  useEffect(() => {
    const handlePageShow = (event) => {
      // Check if the event's persisted property is false (back navigation)
      if (event.persisted) {
        // Reload the page after a brief delay
        setTimeout(() => {
          window.location.reload();
        }, 100); // Adjust the delay as needed
      }
    };

    window.addEventListener("pageshow", handlePageShow);

    return () => {
      window.removeEventListener("pageshow", handlePageShow);
    };
  }, []);

  useEffect(() => {
    loadHints();
  }, []);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      Object.keys(localStorage).forEach((key) => {
        if (key.includes("PushNotification_")) {
          localStorage.removeItem(key);
        }
      });
      axios
        .post(
          `${APIURI}/web-hint/getTeamHints/${sceneID}`,
          {
            pod_id: podData.teamData[0].pod_id,
            team_id: podData.teamData[0]._id,
            game_id: podData.teamData[0].game_id,
          },
          config
        )
        .then((res) => {
          console.log(res);
          handleLockUnlockIconHints(res.data.hintArray);
          handleLockUnlockIconSubHints(res.data.subhintArray);
          if (
            res.data.messageList.length &&
            res.data.messageList[0].Pod_notifications.length > 0
          ) {
            console.log(
              res.data.messageList[0].Pod_notifications,
              "all messages"
            );
            let arry = [];
            res.data.messageList[0].Pod_notifications.forEach((resData) => {
              if (resData.displayAt) {
                arry.push(resData);
                localStorage.setItem(
                  `PushNotification_${Math.floor(
                    Date.parse(resData.displayAt) / 1000
                  )}`,
                  resData.description
                );
              }
            });
            console.log(arry);
          }
        });
      // axios.get(`${APIURI}/web-hint/view/${sceneID}`, config).then((res) => {
      //   handleLockUnlockIconHints(res.data.message);
      //   handleLockUnlockIconSubHints(res.data.message);
      // });
    };

    // Set up the interval to call the API every 10 seconds
    const interval = setInterval(() => {
      if (podData && podData.podStatus === "active") {
        console.log(podData, podData.podStatus);
        fetchData();
      }
    }, 6000); // 10000 milliseconds = 10 seconds

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(interval);
    };
  }, [podData]);

  // useEffect(() => {
  //   // Function to fetch data from the API
  //   const fetchData = async () => {
  //     Object.keys(localStorage).forEach((key) => {
  //       if (key.includes("PushNotification_")) {
  //         localStorage.removeItem(key);
  //       }
  //     });
  //     axios
  //       .get(`${APIURI}/web-pods/allMessages/${podId}`, config)
  //       .then((res2) => {
  //         if (
  //           res2.data.data.length &&
  //           res2.data.data[0].Pod_notifications.length > 0
  //         ) {
  //           console.log(res2.data.data[0].Pod_notifications, "all messages");
  //           let arry = [];
  //           res2.data.data[0].Pod_notifications.forEach((resData) => {
  //             if (resData.displayAt) {
  //               arry.push(resData);
  //               localStorage.setItem(
  //                 `PushNotification_${Math.floor(
  //                   Date.parse(resData.displayAt) / 1000
  //                 )}`,
  //                 resData.description
  //               );
  //             }
  //           });
  //           console.log(arry);

  //           // setPodNotificationsOnTime(arry);
  //         }
  //       });
  //   };

  //   // Set up the interval to call the API every 10 seconds
  //   const interval = setInterval(() => {
  //     if (podId) {
  //       fetchData();
  //     }
  //   }, 5000); // 10000 milliseconds = 10 seconds

  //   // Clean up the interval when the component unmounts
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [podId]);

  useEffect(() => {
    socketNotification.on("message", (data) => {
      console.log(data);
      setPushNotificationModal(true);
      setPushNotificationData(data.description);
    });
    return () => {
      socketNotification.disconnect();
    };
  }, [socketNotification]);

  useEffect(() => {
    const ShowNotificationOnTime = () => {
      // const currentTimeStamp = Date.now();
      // var myDate = new Date(currentTimeStamp);
      // var pstDate = myDate.toLocaleString("en-US", {
      //   timeZone: "America/Los_Angeles",
      // });
      // console.log(Date.parse(pstDate), "pst date");
      // var now = new Date();
      // var utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
      // console.log(
      //   "utcasdf",
      //   Math.floor(Date.parse(new Date().toISOString()) / 1000)
      // );
      let utc_date = Math.floor(Date.parse(new Date().toISOString()) / 1000);

      // var pst_now = new Date(pstDate);
      // console.log("pst to utc ", Date.parse(new Date(pstDate).toISOString()));

      let notificationVar = localStorage.getItem(
        `PushNotification_${utc_date}`
      );
      if (notificationVar) {
        setPushNotificationModal(true);
        setPushNotificationData(notificationVar);
      }
    };

    // Call the function every second (1000 milliseconds)
    const intervalId = setInterval(ShowNotificationOnTime, 1000);

    // To stop calling the function, clear the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  async function handleTooltip() {
    let elements = document.getElementsByClassName("tooltip");
    let arr = await togglePopoverArr;
    console.log(arr);
    let myFunction = function (e, i, acID) {
      e.preventDefault();
      console.log(e, i, arr[0][acID]);
      setTooglePopover(true);
      setTooglePopoverMessage(arr[0][acID]);
    };

    for (let i = 0; i < elements.length; i++) {
      let actionBtn = elements[i];
      let actionBtnId = actionBtn.id.split("-");
      console.log(actionBtnId[1]);
      actionBtn.addEventListener(
        "mousedown",
        (e) => myFunction(e, i, actionBtnId[1]),
        false
      );
    }
  }

  const handleClosePopover = () => {
    setTooglePopover(false);
    // window.location.reload();
    // handleTooltip();
  };

  const handlePenaltyForHints = (e, penalty, id) => {
    if (penaltyShow) {
      let isAccordionOpen;
      // it is working opposite
      if (e.currentTarget.getAttribute("aria-expanded") === "false") {
        isAccordionOpen = false;
      } else {
        isAccordionOpen = true;
      }
      // console.log(penalty);
      if (!isAccordionOpen) {
        const foundHint = hintsLockUnlockStatus.find((data) => data.id === id);
        if (!foundHint) {
          let arry = [...hintsLockUnlockStatus];
          arry.push({ id: id, status: true });
          setHintsLockUnlockStatus(arry);
          let penaltyData = {
            // pod_id: podData.teamData[0].pod_id,
            // team_id: podData.teamData[0]._id,
            // game_id: podData.teamData[0].game_id,
            // scene_id: sceneID,
            // penalty: penalty,
            // hint_id: id,
            pod_id: podData.teamData[0].pod_id,
            team_id: podData.teamData[0]._id,
            game_id: podData.teamData[0].game_id,
            scene_id: sceneID,
            penaltyDuration: penalty,
          };
          axios
            .post(
              `${APIURI}/web-hint/addPenaltyHintPods/${id}`,
              penaltyData,
              config
            )
            .then((res) => {
              console.log(res);
            });
        }
      }
    }
  };

  const handlePenaltyForSubHints = (e, penalty, subhintId, hintId) => {
    if (penaltyShow) {
      let isAccordionOpen;
      // it is working opposite
      if (e.currentTarget.getAttribute("aria-expanded") === "false") {
        isAccordionOpen = false;
      } else {
        isAccordionOpen = true;
      }
      // console.log(penalty);
      if (!isAccordionOpen) {
        const foundHint = subHintsLockUnlockStatus.find(
          (data) => data.id === subhintId
        );
        if (!foundHint) {
          let arry = [...subHintsLockUnlockStatus];
          arry.push({ id: subhintId, status: true });
          setSubHintsLockUnlockStatus(arry);
          console.log(podData);
          let penaltyData = {
            // subhint_id: subhintId,
            hint_id: hintId,
            pod_id: podData.teamData[0].pod_id,
            team_id: podData.teamData[0]._id,
            game_id: podData.teamData[0].game_id,
            scene_id: sceneID,
            penaltyDuration: penalty,
          };
          console.log(penaltyData);
          axios
            .post(
              `${APIURI}/web-hint/addPenaltySubhintPods/${subhintId}`,
              penaltyData,
              config
            )
            .then((res) => {
              console.log(res);
              // if (
              //   res.data.message ===
              //   "Scene is already completed not adding penalties"
              // ) {
              //   setPenaltyErrorModal(true);
              // }
            });
        }
      }
    }
  };

  const handleLockUnlockIconHints = async (hintsDataArry) => {
    console.log(hintsDataArry);
    let arry = [];
    hintsDataArry.map((res) => {
      arry.push({ id: res, status: true });
    });
    // await Promise.all(
    //   hintsData.map((data) => {
    //     return axios
    //       .get(`${APIURI}/web-hint/isHintUsed/${data._id}`, config)
    //       .then((res) => {
    //         console.log(res);
    //         const dynamicKey = data._id;
    //         arry.push({ id: dynamicKey, status: res.data.hintUsed });
    //       })
    //       .catch((error) => {
    //         console.error(error);
    //       });
    //   })
    // );
    console.log(arry);
    setHintsLockUnlockStatus(arry);
  };

  const handleLockUnlockIconSubHints = async (subhintsDataArry) => {
    console.log(subhintsDataArry);
    let arry = [];
    subhintsDataArry.map((res) => {
      arry.push({ id: res, status: true });
    });
    // await Promise.all(
    //   hintsData.map((data) => {
    //     if (data.subhint) {
    //       data.subhint.map((datasubHint) => {
    //         return axios
    //           .get(
    //             `${APIURI}/web-hint/isSubhintUsed/${datasubHint._id}`,
    //             config
    //           )
    //           .then((res) => {
    //             console.log(res);
    //             const dynamicKey = datasubHint._id;
    //             arry.push({ id: dynamicKey, status: res.data.hintUsed });
    //           })
    //           .catch((error) => {
    //             console.error(error);
    //           });
    //       });
    //     }
    //   })
    // );
    console.log(arry);
    setSubHintsLockUnlockStatus(arry);
  };

  const renderLockUnLockHints = (id) => {
    if (penaltyShow) {
      const foundHint = hintsLockUnlockStatus.find((data) => data.id === id);
      if (foundHint) {
        return (
          <img
            src="/assets/unlockIcon.png"
            alt="unlock"
            srcSet=""
            className="lock--unlock--img"
          />
        );
      } else {
        return (
          <img
            src="/assets/lockIcon.png"
            alt="lock"
            srcSet=""
            className="lock--unlock--img"
          />
        );
      }
    }
  };

  const renderLockUnLockSubHints = (id) => {
    if (penaltyShow) {
      const foundHint = subHintsLockUnlockStatus.find((data) => data.id === id);
      if (foundHint) {
        return (
          <img
            src="/assets/unlockIcon.png"
            alt="unlock"
            srcSet=""
            className="lock--unlock--img"
          />
        );
      } else {
        return (
          <img
            src="/assets/lockIcon.png"
            alt="lock"
            srcSet=""
            className="lock--unlock--img"
          />
        );
      }
    }
  };

  function secondsToMinutes(seconds) {
    if (typeof seconds !== "number" || isNaN(seconds)) {
      return "-";
    }

    const minutes = seconds / 60;
    const roundedMinutes = Math.round(minutes * 100) / 100; // Rounding to two decimal places

    return roundedMinutes;
  }

  const loadHints = async () => {
    if (sceneID === undefined) {
      alert("No Scene specified");
    } else {
      setLoad(true);
      await axios
        .get(`${APIURI}/web-hint/view/${sceneID}`, config)
        .then((res) => {
          let hintLength = typeof res.data.message;
          handleTooltip();
          console.log(res.data);
          if (res.data.podBody && res.data.podBody.podStatus !== "inactive") {
            setPodData(res.data.podBody);
            Object.keys(localStorage).forEach((key) => {
              if (key.includes("PushNotification_")) {
                localStorage.removeItem(key);
              }
            });
            axios
              .post(
                `${APIURI}/web-hint/getTeamHints/${sceneID}`,
                {
                  pod_id: res.data.podBody.teamData[0].pod_id,
                  team_id: res.data.podBody.teamData[0]._id,
                  game_id: res.data.podBody.teamData[0].game_id,
                },
                config
              )
              .then((res2) => {
                console.log(res2);
                handleLockUnlockIconHints(res2.data.hintArray);
                handleLockUnlockIconSubHints(res2.data.subhintArray);
                if (
                  res2.data.messageList.length &&
                  res2.data.messageList[0].Pod_notifications.length > 0
                ) {
                  console.log(
                    res2.data.messageList[0].Pod_notifications,
                    "all messages"
                  );
                  let arry = [];
                  res2.data.messageList[0].Pod_notifications.forEach(
                    (resData) => {
                      if (resData.displayAt) {
                        arry.push(resData);
                        localStorage.setItem(
                          `PushNotification_${Math.floor(
                            Date.parse(resData.displayAt) / 1000
                          )}`,
                          resData.description
                        );
                      }
                    }
                  );
                  console.log(arry);
                }
              });
          }
          if (res.data.podBody && res.data.podBody.podStatus === "active") {
            if (res.data.podBody && res.data.podBody.podData[0][0]._id) {
              socketNotification.emit(
                "joinRoom",
                `${res.data.podBody.podData[0][0]._id}`
              );
              setPodId(res.data.podBody.podData[0][0]._id);
            } else {
              console.error(
                "res.data.podBody or res.data.podBody.podData[0][0]._id is not properly defined."
              );
            }
            // socketNotification.emit(
            //   "joinRoom",
            //   `${res.data.podBody.podData[0][0]._id}`
            // );
            setPenaltyShow(true);
            setHindDescPod(res.data.podBody.podData[0][0].hint_description);
          }
          if (hintLength === "object") {
            setHints(res.data.message);
            setHintMetadata(res.data.message[0].hint_metadata);
            setHintAvailable(true);
            setLoad(false);
            window.scrollTo(0, 0);
            console.log(res.data.message);
            let arrPop = togglePopoverArr;
            arrPop.push(
              res.data.message[0].scene_popup.map((te) => te.description)
            );
            setTooglePopoverArr(arrPop);
          } else {
            setLoad(false);
            setHintAvailable(false);
          }
        })
        .catch((err) => {
          console.log(err);
          if (
            err.response &&
            err.response.data.message ===
              "Your team has not started the pod yet"
          ) {
            setPodNotStarted(true);
            if (err.response.data.podBody) {
              setPodId(err.response.data.podBody.podData[0][0]._id);
            }
            setLoad(false);
          } else {
            if (
              err.response &&
              err.response.data &&
              err.response.data.teamRequired
            ) {
              window.location.href = "/drawerpods";
            }
            setLoad(false);
          }

          // alert("No Hints found for this Scene!");
        });
    }
  };

  if (load) {
    return <Loader />;
  }
  function ContentTypeComponent(props) {
    console.log(props);
    let desc = props.content;
    console.log("Scene Details", hints[0].scene_popup);
    const popupData = hints[0].scene_popup;
    popupData.map((te, i) => {
      desc = desc.replaceAll(
        te.identifier,
        `<span class="tooltip" id='tooltip-${i}'>${te.title}</span>`
      );
      handleTooltip();
    });
    return (
      <div
        className="desc-Text-hint"
        dangerouslySetInnerHTML={createMarkup(desc)}
      />
    );
    // return "All";
  }
  return (
    <div className="Hints">
      <table
        style={{
          backgroundColor: "#FFFB00",
          width: "100%",
          paddingTop: "3.45rem",
        }}
      >
        <tr>
          <td style={{ paddingLeft: "22px" }}>
            <button
              onClick={(e) => {
                if (props.handleCloseHint) {
                  props.handleCloseHint();
                } else {
                  history.goBack();
                }
              }}
              className="back_btn_hint"
            >
              <ArrowBackIcon />
            </button>
          </td>
          <td style={{ paddingRight: "22px" }}>
            <h2 className="hints_h2_td">Hints</h2>
          </td>
          <td>
            <button style={{ visibility: "hidden" }}>
              <ArrowBackIcon />
            </button>
          </td>
        </tr>
      </table>
      {/* <div className="Hints-header">
        <h2>Hints</h2>
        <button onClick={(e) => history.goBack()}>
          <ArrowBackIcon />
        </button>{" "}
      </div> */}
      <div className="Hints-container">
        {penaltyShow ? (
          isHintAvailable ? (
            <div dangerouslySetInnerHTML={createMarkup(hindDescPod)} />
          ) : (
            ""
          )
        ) : (
          <div dangerouslySetInnerHTML={createMarkup(hintMetadata)} />
        )}
      </div>
      {isHintAvailable === false ? (
        <div className="Hints-footer">
          <div className="Hints-container">
            <p>No hints needed! Click back to continue play.</p>
          </div>
        </div>
      ) : (
        <div className="Hints-footer">
          {hints.map((hint, i) => {
            return (
              <React.Fragment>
                <Accordion
                  key={hint._id}
                  className="Accordion-Outer"
                  onChange={(e) => {
                    handlePenaltyForHints(
                      e,
                      hint.hint_penalty ? hint.hint_penalty : 0,
                      hint._id
                    );
                  }}
                >
                  <AccordionSummary
                    className="accordion-title"
                    expandIcon={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24"
                        viewBox="0 0 18 18"
                        width="24"
                        style={{ marginLeft: "-9px" }}
                      >
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
                      </svg>
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <p
                      className={
                        // hint.title.length > 21
                        //   ? "hintTitle hint_p_margin_bottom"
                        //   : "hintTitle"
                        "hintTitle hint_p_margin_bottom"
                      }
                      style={{ gridGap: `${penaltyShow ? "10px" : ""}` }}
                    >
                      <span className="hintTitle__items">
                        {renderLockUnLockHints(hint._id)}
                      </span>
                      <span
                        className="hintTitle__items"
                        style={{
                          marginBottom: `${
                            hint.title.length > 21 ? "0" : "-2.5px"
                          }`,
                        }}
                      >
                        {" "}
                        {hint.title}
                      </span>{" "}
                      {penaltyShow ? (
                        <span
                          style={{
                            fontSize: "12px",
                            textTransform: "capitalize",
                            marginTop: "17px",
                          }}
                          className="hintTitle__items"
                        >
                          {hint.hint_penalty
                            ? secondsToMinutes(hint.hint_penalty)
                            : 0}{" "}
                          MIN
                        </span>
                      ) : (
                        <span className="hintTitle__items"></span>
                      )}
                    </p>
                  </AccordionSummary>
                  <AccordionDetails className="accordion-details">
                    {/* <div
                      dangerouslySetInnerHTML={createMarkup(hint.description)}
                    /> */}
                    <ContentTypeComponent content={hint.description} />
                  </AccordionDetails>

                  {hint.subhint.map((subHint, index) => {
                    return (
                      <Accordion
                        key={subHint._id}
                        className="Accordion-Inner"
                        onChange={(e) => {
                          handlePenaltyForSubHints(
                            e,
                            subHint.sub_hint_penalty
                              ? subHint.sub_hint_penalty
                              : 0,
                            subHint._id,
                            hint._id
                          );
                        }}
                      >
                        <AccordionSummary
                          className="Accordion-SubHintTitle"
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <p
                            className="subHintTitle"
                            // style={{
                            //   display: "flex",
                            //   justifyContent: "space-between",
                            //   width: "100%",
                            // }}
                            style={{ gridGap: `${penaltyShow ? "10px" : ""}` }}
                          >
                            <span className="subHintTitle__items">
                              {renderLockUnLockSubHints(subHint._id)}{" "}
                            </span>
                            <span className="subHintTitle__items">
                              {" "}
                              {subHint.title}
                            </span>
                            {penaltyShow ? (
                              <span
                                style={{
                                  fontSize: "12px",
                                  textTransform: "capitalize",
                                  marginTop: "17px",
                                  letterSpacing: ".4px",
                                }}
                                className="subHintTitle__items"
                              >
                                {subHint.sub_hint_penalty
                                  ? secondsToMinutes(subHint.sub_hint_penalty)
                                  : 0}{" "}
                                MIN
                              </span>
                            ) : (
                              <span className="subHintTitle__items"></span>
                            )}
                          </p>
                        </AccordionSummary>
                        <AccordionDetails>
                          {/* <Chip label={`SUBHINT ${i + 1}.${index + 1} `} /> */}
                          <div
                            dangerouslySetInnerHTML={createMarkup(
                              subHint.description
                            )}
                          />
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
                  {hint.custom_url ? (
                    <AccordionSummary
                      style={{ paddingBottom: "5px" }}
                      className={
                        hint.subhint.length ? "" : "handle--hint--lable--btn"
                      }
                    >
                      <div className="btn-last-hint-div">
                        <a
                          className="btn-last-hint"
                          target="_blank"
                          href={hint.custom_url}
                        >
                          {hint.custom_lable}
                        </a>
                      </div>
                    </AccordionSummary>
                  ) : (
                    <></>
                  )}
                </Accordion>
              </React.Fragment>
            );
          })}
        </div>
      )}
      {/* <LogoutComponent /> */}
      <Dialog
        open={togglePopover}
        onClose={handleClosePopover}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="PopOver"
      >
        {/* <DialogTitle id="alert-dialog-title">Message</DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div dangerouslySetInnerHTML={createMarkup(togglePopoverMessage)} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClosePopover}
            className="dialog-btn-dismiss"
            style={{ marginBottom: "2.2rem" }}
            autoFocus
            variant="contained"
          >
            Okay
          </Button>
          {/* <Camera onTakePhoto = { (dataUri) => { handleTakePhoto(dataUri); } }/> */}
        </DialogActions>
      </Dialog>

      <Dialog
        open={penaltyErrorModal}
        onClose={() => {
          setPenaltyErrorModal(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="PopOver"
      >
        {/* <DialogTitle id="alert-dialog-title">Message</DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div>
              <p>Scene is already completed not adding penalties</p>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setPenaltyErrorModal(false);
            }}
            className="dialog-btn-dismiss"
            style={{ marginBottom: "2.2rem" }}
            autoFocus
            variant="contained"
          >
            Okay
          </Button>
          {/* <Camera onTakePhoto = { (dataUri) => { handleTakePhoto(dataUri); } }/> */}
        </DialogActions>
      </Dialog>

      {/* push nofication  */}
      <Dialog
        open={pushNotificationModal}
        onClose={() => {
          setPushNotificationModal(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="PopOver"
      >
        {/* <DialogTitle id="alert-dialog-title">Message</DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {/* <div dangerouslySetInnerHTML={createMarkup(pushNotificationData)} /> */}
            <div>
              <p>{pushNotificationData}</p>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setPushNotificationModal(false);
            }}
            style={{ marginBottom: "2.2rem" }}
            className="dialog-btn-dismiss"
            autoFocus
            variant="contained"
          >
            Okay
          </Button>
          {/* <Camera onTakePhoto = { (dataUri) => { handleTakePhoto(dataUri); } }/> */}
        </DialogActions>
      </Dialog>

      {/* not started pod  */}
      <Dialog
        open={podNotStarted}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
          {"START YOUR TIME"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            className="dialog-desc"
          >
            Your team has not clicked start. Return to your team homepage below,
            click start, then rescan this code to play.
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ padding: "2em" }}>
          <Button
            className="dialog-btn-dismiss"
            autoFocus
            variant="contained"
            onClick={() => {
              window.location.href = `/pods:${podId}`;
            }}
          >
            TEAM HOMEPAGE
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default withRouter(Hints);

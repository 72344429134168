import React from "react";
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";

export default function Bar(props) {
  const { duration, curTime, onTimeUpdate } = props;

  const curPercentage = (curTime / duration) * 100;
  // console.log("Audio COM", curPercentage)
  function formatDuration(duration) {
    return moment
      .duration(duration, "seconds")
      .format("mm:ss", { trim: false });
  }

  function calcClickedTime(e) {
    const clickPositionInPage = e.pageX;
    const bar = document.querySelector(".bar__progress");
    const barStart = bar.getBoundingClientRect().left + window.scrollX;
    const barWidth = bar.offsetWidth;
    const clickPositionInBar = clickPositionInPage - barStart;
    const timePerPixel = duration / barWidth;
    return timePerPixel * clickPositionInBar;
  }

  function handleTimeDrag(e) {
    onTimeUpdate(calcClickedTime(e));

    const updateTimeOnMove = eMove => {
      onTimeUpdate(calcClickedTime(eMove));
    };

    document.querySelector(".bar__progress").addEventListener("pointermove", (eMove)=>{updateTimeOnMove(eMove);console.log(eMove)});

    document.querySelector(".bar__progress").addEventListener("pointerup", () => {
      console.log("pointer Up")
      document.querySelector(".bar__progress").removeEventListener("touchmove", (eMove)=>{updateTimeOnMove(eMove)});
    });
    
  }
  

  return (
    <div className="bar">
      {/* <span className="bar__time">{formatDuration(curTime)}</span> */}
      {(curPercentage > 0)? (
      <div
        className="bar__progress"
        style={{
          background: `linear-gradient(to right, black ${curPercentage}%, #e5e5e5 0)`
        }}
        draggable="true"
        // onMouseDown={e => handleTimeDrag(e)}
        // onPointerDown={e => handleTimeDrag(e)}
        onPointerUp={e => handleTimeDrag(e)}
        // pointerup
        
        
       
      >
        <span
          className="bar__progress__knob"
          style={{ left: `${curPercentage - 2}%` }}
        />
      </div>):(
       <div
        className="bar__progress"
        style={{
          background: '#e5e5e5'
        }}
        draggable="true"
        // onMouseDown={e => handleTimeDrag(e)}
        // onPointerDown={e => handleTimeDrag(e)}
        onPointerUp={e => handleTimeDrag(e)}
      >
        <span
          className="bar__progress__knob"
          style={{ left: `${curPercentage - 2}%` }}
        />
      </div>)}
      {/* <span className="bar__time">{formatDuration(duration)}</span> */}
    </div>
  );
}

import React, { useState } from "react";
import {
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import axios from "axios";
import { APIURI } from "../config";
import { useHistory } from "react-router";

const ChangePasswordScreen = ({ uploadImage }) => {
  const [changePasswordDetails, setChangePasswordDetails] = useState({
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);
  const accessToken = localStorage.getItem("accesstoken");
  let config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const randomName = `input_${Math.random()}`;

  const changePassword = (e) => {
    e.preventDefault();
    setErrorMessage("");
    let data = {
      oldPassword: changePasswordDetails.oldPassword,
      newPassword: changePasswordDetails.newPassword,
    };
    if (
      changePasswordDetails.newPassword ===
      changePasswordDetails.confirmNewPassword
    ) {
      setBtnLoading(true);
      if (uploadImage) {
        const formData = new FormData();
        formData.append("", uploadImage);
        axios
          .post(`${APIURI}/web-user/uploadProfilePicture`, formData, config)
          .then((res) => {
            console.log(res);
            localStorage.setItem("Updated_Profile_Pic", res.data.data);
            axios
              .post(`${APIURI}/web-user/changePassword`, data, config)
              .then((res2) => {
                console.log(res2);
                // history.push("/profile");
                setBtnLoading(false);
                window.location.href = "/profile";
              })
              .catch((err) => {
                console.log(err.response);
                setBtnLoading(false);
                setErrorMessage(err.response.data.message);
              });
          })
          .catch((err) => {
            console.log(err);
            setErrorMessage("Unable to update image");
            setBtnLoading(false);
          });
      } else {
        axios
          .post(`${APIURI}/web-user/changePassword`, data, config)
          .then((res) => {
            console.log(res);
            // history.push("/profile");
            window.location.href = "/profile";
            setBtnLoading(false);
          })
          .catch((err) => {
            console.log(err.response);
            setBtnLoading(false);
            setErrorMessage(err.response.data.message);
          });
      }
    } else {
      setErrorMessage("Confirm Password didn't match.");
    }
  };

  return (
    <div className="Auth-login edit-screeens">
      {/* <div className="Auth-header">
        <div className="container">
          <img
            // src="/assets/Escapely@3x.png"
            src="/assets/escapely3Xyellow.png"
            alt="Logo"
            style={{ width: "100%" }}
          />
        </div>
        <p>Change Password</p>
      </div> */}
      <div className="Auth-container">
        <input
          type="password"
          className="login-input"
          placeholder="OLD PASSWORD"
          autoComplete="new-password"
          name={randomName}
          value={changePasswordDetails.oldPassword}
          onChange={(e) => {
            setChangePasswordDetails({
              ...changePasswordDetails,
              oldPassword: e.target.value,
            });
            setErrorMessage("");
          }}
        />
        <label>OLD PASSWORD</label>
        <br />
        <div className="password-txt">
          <input
            type="password"
            placeholder="NEW PASSWORD"
            className="login-input password-circle"
            autoComplete="new-password"
            name={randomName}
            value={changePasswordDetails.newPassword}
            onChange={(e) => {
              setChangePasswordDetails({
                ...changePasswordDetails,
                newPassword: e.target.value,
              });
              setErrorMessage("");
            }}
          />
          <label>NEW PASSWORD</label>
        </div>
        <br />
        <div className="password-txt">
          <input
            type="password"
            placeholder="CONFIRM NEW PASSWORD"
            className="login-input password-circle"
            autoComplete="new-password"
            name={randomName}
            value={changePasswordDetails.confirmNewPassword}
            onChange={(e) => {
              setChangePasswordDetails({
                ...changePasswordDetails,
                confirmNewPassword: e.target.value,
              });
              setErrorMessage("");
            }}
            a
          />
          <label>CONFIRM NEW PASSWORD</label>
        </div>
        <br />
        <p className="wrong-ans">{errorMessage}</p>
        <Button
          className="signin"
          style={{
            opacity: `${
              !changePasswordDetails.newPassword ||
              !changePasswordDetails.oldPassword ||
              !changePasswordDetails.confirmNewPassword ||
              btnLoading
                ? "0.8"
                : "1"
            }`,
          }}
          variant="contained"
          onClick={changePassword}
          disabled={
            !changePasswordDetails.newPassword ||
            !changePasswordDetails.oldPassword ||
            !changePasswordDetails.confirmNewPassword ||
            btnLoading
          }
        >
          Change Password
        </Button>
      </div>
    </div>
  );
};

export default ChangePasswordScreen;

import React, { useEffect } from "react";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import EditIcon from "@material-ui/icons/Edit";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import { useHistory } from "react-router";
import { useState } from "react";
import axios from "axios";
import { APIURI } from "../config";
import Loader from "../utils/Loader";
import Footer from "./Footer";

const Tab = (props) => {
  return <>{props.children}</>;
};

const TabButtons = ({ buttons, changeTab, activeTab }) => {
  return (
    <div className="tab-buttons game__history__tab__strap">
      {buttons.map((button, index) => {
        return (
          <button
            className={button === activeTab ? "active" : ""}
            onClick={() => changeTab(button)}
            key={index}
          >
            {button}
          </button>
        );
      })}
    </div>
  );
};

function Tabs({ children }) {
  const [activeTab, setActiveTab] = useState(children[0].props.label);

  const changeTab = (tab) => {
    setActiveTab(tab);
  };

  let content;
  let buttons = [];

  React.Children.forEach(children, (child) => {
    buttons.push(child.props.label);
    if (child.props.label === activeTab) content = child.props.children;
  });

  return (
    <div style={{ width: "100%" }}>
      <div>
        <TabButtons
          activeTab={activeTab}
          buttons={buttons}
          changeTab={changeTab}
        />
      </div>
      <div className="tab-content">{content}</div>
    </div>
  );
}

const ProfileScreen = () => {
  const [userDetails, setUserDetails] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [gameData, setGameData] = useState([]);
  const [podData, setPodData] = useState([]);
  const [gameLength, setGameLength] = useState(2);
  const [podLength, setPodLength] = useState(2);
  const history = useHistory();
  const accessToken = localStorage.getItem("accesstoken");
  const update_pic_from_localStorage = localStorage.getItem(
    "Updated_Profile_Pic"
  );
  let config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const getUser = async () => {
    await axios
      .get(`${APIURI}/web-user/getUser`, config)
      .then((res) => {
        console.log(res);
        setUserDetails(res.data.userData);
        // setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        history.push("/auth");
      });
  };

  const getUserPodDetails = async () => {
    let gamesListWithPod = [];
    await axios
      .get(`${APIURI}/web-user/podsPlayed`, config)
      .then((res) => {
        console.log("podDetails", res);
        setPodData(res.data.data.reverse());
        res.data.data.forEach((pod) => {
          gamesListWithPod.push({ [pod.played_at]: pod.GameTitle });
        });
        console.log(gamesListWithPod);
        axios
          .get(`${APIURI}/web-user/gamesPlayed`, config)
          .then((res2) => {
            console.log("gameDetails", res2);
            // setGameData(res2.data.GameData);
            res2.data.GameData.forEach((game) => {
              gamesListWithPod.push({ [game.createdAt]: game.game_title });
            });
            console.log(gamesListWithPod);

            // logic to sort date
            gamesListWithPod.sort((a, b) => {
              const dateA = new Date(Object.keys(a)[0]);
              const dateB = new Date(Object.keys(b)[0]);
              return dateB - dateA;
            });
            console.log(gamesListWithPod);
            setGameData(gamesListWithPod);

            setIsLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setIsLoading(false);
          });
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  // const getUserGamesDetails = async () => {
  //   await axios
  //     .get(`${APIURI}/web-user/gamesPlayed`, config)
  //     .then((res) => {
  //       console.log("gameDetails", res);
  //       setGameData(res.data.GameData);
  //       // setIsLoading(false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setIsLoading(false);
  //     });
  // };

  useEffect(() => {
    setTimeout(() => {
      getUserPodDetails();
      // getUserGamesDetails();
      getUser();
    }, 3000);
    return () => {
      clearTimeout();
    };
  }, []);

  if (isLoading) return <Loader />;

  return (
    <div className="profile__main__container">
      <section className="back__edit__section">
        {localStorage.getItem("backLink") ? (
          <ArrowBackIosIcon
            onClick={() => {
              window.location.href = localStorage.getItem("backLink");
            }}
          />
        ) : (
          <span></span>
        )}

        <img
          src="/assets/EditIcon.svg"
          alt="edit_icon"
          srcSet=""
          onClick={() => {
            history.push("/editprofile");
          }}
        />
      </section>
      <section className="user__detials__section">
        {userDetails && userDetails.profilePicLocation ? (
          <img
            src={userDetails && userDetails.profilePicLocation}
            alt=""
            srcSet=""
            style={{
              height: "150px",
              width: "150px",
              borderRadius: "50%",
              objectFit: "cover",
              marginBottom: "8px",
            }}
          />
        ) : (
          <img
            src="/assets/AccountIcon_Yellow-01.svg"
            alt="account_icon"
            srcSet=""
          />
        )}
        <span>{userDetails && userDetails.name}</span>
        <span>{userDetails && userDetails.email}</span>
      </section>
      <section className="game__history__section">
        <div className="game__history__strap">
          <span>Game History</span>
        </div>
        <section className="tab__section">
          {/* <span>Game History</span> */}
          <Tabs>
            <Tab label="GAMES">
              <div className="contests__main__container">
                {gameData.length &&
                  gameData.map((data, index) => {
                    let date = Object.keys(data)[0];
                    console.log(Object.keys(data)[0]);
                    let title = data[date];
                    return (
                      <div className="user__contest__details" key={index}>
                        <span style={{ textTransform: "uppercase" }}>
                          {/* {data.game_title} */}
                          {title}
                        </span>
                        <br />
                        {/* 2023-08-10T13:05:50.594Z */}
                        <span
                          style={{ fontSize: "14px" }}
                          className="content__for__raleway__font"
                        >
                          {`${date.split("T")[0].split("-")[1]}|${
                            date.split("T")[0].split("-")[2]
                          }|${date.split("T")[0].split("-")[0]}`}
                        </span>
                      </div>
                    );
                  })}
              </div>

              {/* {gameData.length &&
                gameLength < gameData.length &&
                gameData.length !== gameLength && (
                  <div className="see__all__btn">
                    <button
                      onClick={() => {
                        setGameLength(gameData.length);
                      }}
                    >
                      SEE ALL
                    </button>
                  </div>
                )} */}
            </Tab>
            <Tab label="CONTESTS">
              <div className="contests__main__container">
                {podData.length &&
                  podData.map((data, index) => {
                    return (
                      <div className="user__contest__details" key={index}>
                        <span style={{ textTransform: "uppercase" }}>
                          {data.podTitle}
                        </span>
                        <br />
                        <span className="content__for__raleway__font">
                          TEAM NAME:{" "}
                          <span style={{ color: "#fffb01" }}>
                            {data.teamTitle}
                          </span>
                        </span>
                        <br />

                        <span className="content__for__raleway__font">
                          RANK:{" "}
                          <span style={{ color: "#fffb01" }}>
                            {data.rank && typeof data.rank === "number"
                              ? data.rank
                              : "-"}
                          </span>
                        </span>
                        <br />

                        <span
                          style={{ fontSize: "14px" }}
                          className="content__for__raleway__font"
                        >
                          {" "}
                          {`${data.played_at.split("T")[0].split("-")[1]}|${
                            data.played_at.split("T")[0].split("-")[2]
                          }|${data.played_at.split("T")[0].split("-")[0]}`}
                        </span>
                      </div>
                    );
                  })}
              </div>
              {/* {podData.length &&
                podLength < podData.length &&
                podData.length !== podLength && (
                  <div className="see__all__btn">
                    <button
                      onClick={() => {
                        setPodLength(podData.length);
                      }}
                    >
                      SEE ALL
                    </button>
                  </div>
                )} */}
            </Tab>
          </Tabs>
        </section>
      </section>
      {/* <Footer isLoginRequired={true} /> */}
    </div>
  );
};

export default ProfileScreen;

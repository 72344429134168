import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import createMarkup from "../utils/CreateMarkup";
import Loader from "../utils/Loader";
import CaptureImages from "./CaptureImages";
import Footer from "./Footer";

export default function DrawerSplash() {
  let history = useHistory();
  const [game, setGame] = useState([]);
  const [isPodActive, setIsPodActive] = useState(false);
  const [podFrames, setPodFrames] = useState([]);

  const [togglePopover, setTooglePopover] = useState(false);
  const [togglePopoverMessage, setTooglePopoverMessage] = useState("");
  const [togglePopoverArr, setTooglePopoverArr] = useState([]);
  const [load, setLoad] = useState(false);
  let addNew = [];
  if (JSON.parse(localStorage.getItem("add-new")) !== null) {
    addNew = JSON.parse(localStorage.getItem("add-new")).message;
    console.log(addNew);
  }
  async function handleTooltip() {
    let elements = document.getElementsByClassName("tooltip");
    let arr = await togglePopoverArr;
    let myFunction = function (e, i, acID) {
      e.preventDefault();
      console.log(e, i, arr[0][acID]);
      setTooglePopover(true);
      setTooglePopoverMessage(arr[0][acID]);
    };

    for (let i = 0; i < elements.length; i++) {
      let actionBtn = elements[i];
      let actionBtnId = actionBtn.id.split("-");
      console.log(actionBtnId[1]);
      actionBtn.addEventListener(
        "mousedown",
        (e) => myFunction(e, i, actionBtnId[1]),
        false
      );
    }
  }
  const handleClosePopover = () => {
    setTooglePopover(false);
  };

  const renderDesc = (desc) => {
    if (desc.includes("#cameraSection")) {
      return (
        <>
          <div
            className="confirmation-screen"
            dangerouslySetInnerHTML={createMarkup(
              desc.split("#cameraSection")[0]
            )}
          />
          <div className="confirmation-screen">
            <CaptureImages
              frames={
                isPodActive
                  ? podFrames.length > 0
                    ? podFrames
                    : game[0].Frames
                  : game[0].Frames
              }
              color="#b2b2b2"
            />
          </div>
          {desc.split("#cameraSection")[1] ? (
            <div
              className="confirmation-screen"
              dangerouslySetInnerHTML={createMarkup(
                `<p>${desc.split("#cameraSection")[1]}`
              )}
            />
          ) : (
            ""
          )}
        </>
      );
    } else {
      return (
        <div
          className="confirmation-screen"
          dangerouslySetInnerHTML={createMarkup(desc)}
        />
      );
    }
  };

  const LoadPops = () => {
    const FS = localStorage.getItem("gameFinalScreen");
    if (
      JSON.parse(localStorage.getItem("locationRes")).podBody &&
      JSON.parse(localStorage.getItem("locationRes")).podBody.podStatus ===
        "active"
    ) {
      setIsPodActive(true);
      setPodFrames(
        JSON.parse(localStorage.getItem("locationRes")).podBody.podData[0][0]
          .Frames
      );
    }
    console.log(JSON.parse(localStorage.getItem("locationRes")));
    const game = JSON.parse(FS);
    setGame(game);
    console.log(game, "games");
    let arrPop = togglePopoverArr;
    console.log(game[0].game_popup);
    arrPop.push(game[0].game_popup.map((te) => te.description));
    setTooglePopoverArr(arrPop);
    setTimeout(() => {
      handleTooltip();
    }, 2000);
  };
  useEffect(() => {
    setLoad(true);
    LoadPops();
    setTimeout(() => {
      UpdateMailToLinks();
    }, 1);
  }, []);
  function UpdateMailToLinks() {
    let allA = document.getElementsByTagName("A");
    for (let i = 0, len = allA.length; i < len; i++) {
      console.log("mailto: llink for loop", allA[i]);
      if (allA[i].href.includes("mailto:")) {
        allA[i].className = "style--for--mailto--drawer";
      }
    }
    setLoad(false);
  }
  const handleFinalScreen = (e, uri) => {
    e.preventDefault();
    console.log(uri);
    if (uri !== null && uri !== undefined && uri !== "") {
      window.open(uri, "_blank");
    }
  };
  return (
    <div className="drawersplash">
      {/* <div className="close">
        <button onClick={(e) => history.goBack()}>
          <span>[x]</span>Close
        </button>
      </div> */}
      <div className="container-img">
        <img
          src="/assets/Lock@3x.png"
          alt=""
          style={{ width: "70px", height: "120px" }}
        />
      </div>
      <div className="container-text">
        {game.map((field) => {
          let desc = field.confirmation_message;
          {
            addNew ? (
              addNew.map((te) => {
                for (const [key, value] of Object.entries(te)) {
                  // console.log(key, value);
                  desc = desc.replaceAll("#" + key, value);
                }
              })
            ) : (
              <></>
            );
          }
          game[0].game_popup.map((te, i) => {
            if (te.icon) {
              desc = desc.replaceAll(
                te.identifier,
                `<span class="tooltip" id='tooltip-${i}'><img src="${te.icon}" alt="PopupIcon" /><span>${te.title}</span></span>`
              );
            } else {
              desc = desc.replaceAll(
                te.identifier,
                `<span class="tooltip" id='tooltip-${i}'><img src ="/assets/PopupIcon.svg" alt="PopupIcon" /><span>${te.title}</span></span>`
              );
            }
            handleTooltip();
          });
          return (
            <>
              <div style={{ paddingBottom: "100px" }}>
                {renderDesc(desc)}
                {field.show_final_screen ? (
                  <div style={{ textAlign: "center" }}>
                    <Button
                      target="_blank"
                      onClick={(e) =>
                        handleFinalScreen(e, field.final_screen[0].value)
                      }
                      className="btn-FinalScreen"
                    >
                      {field.final_screen[0].key}
                    </Button>
                  </div>
                ) : (
                  <p style={{ backgroundColor: "#000", display: "none" }} />
                )}
              </div>
              <Footer
                isLoginRequired={game[0].login_required}
                tutorialLink={`/tutorial:${game[0]._id}`}
              />
            </>
          );
        })}
      </div>
      {/* <div className="container-text">
        <p>Please scan the Scene QR Code</p>
      </div> */}
      <Dialog
        open={togglePopover}
        onClose={handleClosePopover}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="PopOver"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div dangerouslySetInnerHTML={createMarkup(togglePopoverMessage)} />{" "}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClosePopover}
            className="dialog-btn-dismiss"
            style={{ marginBottom: "2.2rem" }}
            autoFocus
            variant="contained"
          >
            Okay
          </Button>
          {/* <Camera onTakePhoto = { (dataUri) => { handleTakePhoto(dataUri); } }/> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}

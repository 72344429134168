/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import {
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
} from "@material-ui/core";
import { useHistory, withRouter } from "react-router";
import { useState, useEffect } from "react";
import axios from "axios";
import { APIURI, SOCKET_URI } from "../config";
import LogoutComponent from "./Logout";
import Swal from "sweetalert2";
// import "react-html5-camera-photo/build/css/index.css";
import Audio from "../utils/Audios/Audio";
import Loader from "../utils/Loader";
import Video from "../utils/Videos/Video";
import CaptureImages from "./CaptureImages";
import Footer from "./Footer";
import io from "socket.io-client";
const socketNotification = io(`${SOCKET_URI}/notification`, {
  reconnection: true, // Enable automatic reconnection
  reconnectionAttempts: 10, // Number of reconnection attempts
  reconnectionDelay: 2000, // Delay between reconnection attempts (in milliseconds)
});

function LocationStory(props) {
  let history = useHistory();
  const gameId = props.match.params.gameID;

  const [game, setGame] = useState([]);
  const [isGameAvailable, setGameAvailable] = useState(false);
  const [load, setLoad] = useState(true);
  const [isSuccess, setSuccess] = useState(false);
  const [fields, setFields] = useState([]);
  const [GameDescription, setGameDescription] = useState([]);
  const [gameFields, setGameFields] = useState([
    { label: "", input_value: "" },
  ]);
  const [togglePopover, setTooglePopover] = useState(false);
  const [togglePopoverMessage, setTooglePopoverMessage] = useState("");
  const [togglePopoverArr, setTooglePopoverArr] = useState([]);
  const [gameInputFields, setGameInputFields] = useState();

  const [defaultFields, setDefaultFields] = useState([]);
  const [isDefaultComponent, setDefaultComponent] = useState(false);
  const [isCustomComponent, setCustomComponent] = useState(false);
  const [checkBoxText, setCheckBoxText] = useState("");
  const [userInputField, setUserInputField] = useState();
  const [isCheckedTrue, setIsCheckedTrue] = useState(false);
  const [isAccessCodeRequired, setIsAccessCodeRequired] = useState(false);
  const [accessCode, setAccessCode] = useState("");
  const [incorrectCodeError, setIncorrectCodeError] = useState("");
  const [callApiAgain, setCallApiAgain] = useState(0);
  const [frames, setFrames] = useState("");
  const [isLoginRequired, setIsLoginRequired] = useState(false);
  const [isPodActive, setIsPodActive] = useState(false);
  const [podFrames, setPodFrames] = useState([]);
  const [pushNotificationModal, setPushNotificationModal] = useState(false);
  const [podNotificationsOnTime, setPodNotificationsOnTime] = useState([]);
  const [pushNotificationData, setPushNotificationData] = useState("");
  const [podNotStarted, setPodNotStarted] = useState(false);
  const [podId, setPodId] = useState("");
  const accessToken = localStorage.getItem("accesstoken");
  let config;
  if (accessCode) {
    config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        accesscode: accessCode,
      },
    };
  } else {
    config = {
      headers: { Authorization: `Bearer ${accessToken}` },
    };
  }

  function handelConfig() {
    if (accessCode) {
      config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          accesscode: accessCode,
        },
      };
    } else {
      config = {
        headers: { Authorization: `Bearer ${accessToken}` },
      };
    }
  }

  const renderDesc = (desc) => {
    console.log(desc);
    console.log(desc.split("#cameraSection"), "camerasection1");
    if (desc.includes("#cameraSection")) {
      return (
        <>
          {desc.split("#cameraSection")[0] &&
          desc.split("#cameraSection")[0] !== "<p>" ? (
            <p
              className="descGame"
              dangerouslySetInnerHTML={createMarkup(
                desc.split("#cameraSection")[0]
              )}
            />
          ) : (
            ""
          )}

          <div
            className="location--capture--image"
            style={{ margin: "0 auto" }}
          >
            <CaptureImages
              frames={
                isPodActive
                  ? podFrames.length > 0
                    ? podFrames
                    : frames
                  : frames
              }
              color="#000"
            />
          </div>
          {desc.split("#cameraSection")[1] &&
          desc.split("#cameraSection")[1] !== "</p>" ? (
            <p
              className="descGame"
              dangerouslySetInnerHTML={createMarkup(
                `${desc
                  .split("#cameraSection")[1]
                  .replaceAll("</p>", "")
                  .replaceAll("&nbsp;", "")}`
              )}
            />
          ) : (
            ""
          )}
        </>
      );
    } else {
      return (
        <p className="descGame" dangerouslySetInnerHTML={createMarkup(desc)} />
      );
    }
  };

  async function handleTooltip() {
    let elements = document.getElementsByClassName("tooltip");
    let arr = await togglePopoverArr;
    let myFunction = function (e, i, acID) {
      e.preventDefault();
      console.log(e, i, arr[0][acID]);
      setTooglePopover(true);
      setTooglePopoverMessage(arr[0][acID]);
    };

    for (let i = 0; i < elements.length; i++) {
      let actionBtn = elements[i];
      let actionBtnId = actionBtn.id.split("-");
      console.log(actionBtnId[1]);
      actionBtn.addEventListener(
        "mousedown",
        (e) => myFunction(e, i, actionBtnId[1]),
        false
      );
    }
  }

  function createMarkup(data) {
    return { __html: data };
  }
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const handleLocation = (event) => {
    setLoad(true);
    event.preventDefault();
    console.log(gameFields);
    const newData = {
      game_id: gameId,
      user_id: "placeholder",
      input_fields: gameFields,
    };
    console.log("SentData:", newData);
    axios
      .put(`${APIURI}/web-game/add-new`, newData, config)
      .then((res) => {
        //    Toast.fire({
        //         icon: 'success',
        //       title: 'Saved Successfully!'
        //    })
        setLoad(false);
        console.log("res.data.message arry", res.data.message);
        localStorage.setItem("add-new", JSON.stringify(res.data));
        history.push("/drawer");
        // window.location.reload();
      })
      .catch((err) => {
        //     Toast.fire({
        //     icon: 'error',
        //       title: err.message
        //    })
        setLoad(false);
      });
  };
  function UpdateMailToLinks() {
    let allA = document.getElementsByTagName("A");
    for (let i = 0, len = allA.length; i < len; i++) {
      console.log("mailto: llink for loop", allA[i]);
      if (allA[i].href.includes("mailto:")) {
        allA[i].className = "style--for--mailto--location";
      }
    }
  }
  localStorage.setItem("redirect_url", window.location.href);

  useEffect(() => {
    const handlePageShow = (event) => {
      // Check if the event's persisted property is false (back navigation)
      if (event.persisted) {
        // Reload the page after a brief delay
        setTimeout(() => {
          window.location.reload();
        }, 100); // Adjust the delay as needed
      }
    };

    window.addEventListener("pageshow", handlePageShow);

    return () => {
      window.removeEventListener("pageshow", handlePageShow);
    };
  }, []);

  useEffect(() => {
    handelConfig();
    console.log(window.location.href);
    console.log("useeffect on location story");
    window.scrollTo(0, 0);
    setDefaultComponent(false);
    setTimeout(() => {
      loadGame();
    }, [2000]);
  }, [callApiAgain]);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      Object.keys(localStorage).forEach((key) => {
        if (key.includes("PushNotification_")) {
          localStorage.removeItem(key);
        }
      });
      axios
        .get(`${APIURI}/web-pods/allMessages/${podId}`, config)
        .then((res2) => {
          if (
            res2.data.data.length &&
            res2.data.data[0].Pod_notifications.length > 0
          ) {
            console.log(res2.data.data[0].Pod_notifications, "all messages");
            let arry = [];
            res2.data.data[0].Pod_notifications.forEach((resData) => {
              if (resData.displayAt) {
                arry.push(resData);
                localStorage.setItem(
                  `PushNotification_${Math.floor(
                    Date.parse(resData.displayAt) / 1000
                  )}`,
                  resData.description
                );
              }
            });
            console.log(arry);

            // setPodNotificationsOnTime(arry);
          }
        });
    };

    // Set up the interval to call the API every 10 seconds
    const interval = setInterval(() => {
      if (podId) {
        fetchData();
      }
    }, 5000); // 10000 milliseconds = 10 seconds

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(interval);
    };
  }, [podId]);

  useEffect(() => {
    const ShowNotificationOnTime = () => {
      // const currentTimeStamp = Date.now();
      // var myDate = new Date(currentTimeStamp);
      // var pstDate = myDate.toLocaleString("en-US", {
      //   timeZone: "America/Los_Angeles",
      // });
      // console.log(Date.parse(pstDate), "pst date");
      // var now = new Date();
      // var utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
      // console.log(
      //   "utcasdf",
      //   Math.floor(Date.parse(new Date().toISOString()) / 1000)
      // );
      let utc_date = Math.floor(Date.parse(new Date().toISOString()) / 1000);

      // var pst_now = new Date(pstDate);
      // console.log("pst to utc ", Date.parse(new Date(pstDate).toISOString()));

      let notificationVar = localStorage.getItem(
        `PushNotification_${utc_date}`
      );
      if (notificationVar) {
        setPushNotificationModal(true);
        setPushNotificationData(notificationVar);
      }
    };

    // Call the function every second (1000 milliseconds)
    const intervalId = setInterval(ShowNotificationOnTime, 1000);

    // To stop calling the function, clear the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    handelConfig();
    UpdateMailToLinks();
  }, [load, callApiAgain]);
  // useEffect(() => {
  //   axios
  //     .get(`${APIURI}/web-game/fetchAccessStatusUser/${gameId}`, config)
  //     .then((res) => {
  //       console.log(res, "148");
  //       if (res.data.message.status !== "None") {
  //         setIsAccessCodeRequired(true);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err.message);
  //       if (err.response.status === 401) {
  //         setIsAccessCodeRequired(true);
  //       }
  //     });
  // }, []);

  useEffect(() => {
    socketNotification.on("message", (data) => {
      console.log(data);
      setPushNotificationModal(true);
      setPushNotificationData(data.description);
    });
    return () => {
      socketNotification.disconnect();
    };
  }, [socketNotification]);

  const loadGame = async () => {
    setLoad(true);

    // if (accessToken === null) {
    //   if (gameId === undefined) {
    //     window.location.href = "/auth";
    //   } else {
    //     window.location.href = `/auth:${gameId}/${0}`;
    //   }
    // } else {

    await axios
      .get(`${APIURI}/web-game/view/${gameId}`, config)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.podBody && res.data.podBody.podStatus === "active") {
            if (res.data.podBody && res.data.podBody.podData[0][0]._id) {
              socketNotification.emit(
                "joinRoom",
                `${res.data.podBody.podData[0][0]._id}`
              );
            } else {
              console.error(
                "res.data.podBody or res.data.podBody.podData[0][0]._id is not properly defined."
              );
            }
            // socketNotification.emit(
            //   "joinRoom",
            //   `${res.data.podBody.podData[0][0]._id}`
            // );
            setIsPodActive(true);
            setPodId(res.data.podBody.podData[0][0]._id);
            setPodFrames(res.data.podBody.podData[0][0].Frames);
          }
          console.log("Game Details Array", res.data.message);
          let arr = [];
          setIsLoginRequired(res.data.message.login_required);
          localStorage.setItem(
            "isLoginRequired",
            res.data.message.login_required
          );
          if (
            !res.data.message.login_required &&
            res.data.message.accessToken
          ) {
            localStorage.setItem("accesstoken", res.data.message.accessToken);
          }
          arr.push(res.data.message);
          setGame(arr);
          setGameDescription(res.data.message.game_description);
          setCheckBoxText(res.data.message.checkbox_text);
          setFrames(res.data.message.Frames);
          console.log(3);
          localStorage.setItem("gameFinalScreen", JSON.stringify(arr));
          localStorage.setItem("locationRes", JSON.stringify(res.data));
          let arrPop = togglePopoverArr;
          arrPop.push(res.data.message.game_popup.map((te) => te.description));
          setTooglePopoverArr(arrPop);
          handleTooltip();
          // let desc = res.data.message.description;
          // let desc1 = "";
          // const splitText = desc.split(" ");
          // for (let index = 0; index < splitText.length; index++) {
          //   // console.log(splitText[index]);
          //   // desc1 = desc1.append(replaceURLs(splitText[index]));
          //   console.log(replaceURLs(splitText[index]));
          // }
          // console.log(desc1);
          //  setDescription(desc);

          // setDefaultFields(res.data.message[0].game_input_fields);
          // var anchors = document
          //   .querySelector(".location-container")
          //   .getElementsByTagName("a");
          // for (var i = 0; i < anchors.length; i++) {
          //   anchors[i].setAttribute("target", "_blank");
          // }
          if (res.data.message.game_input_fields.length <= 0) {
            history.push("/drawer");
          } else {
            let value = [];
            setGameInputFields(res.data.message.game_input_fields);
            console.log(
              "game_input_fields",
              res.data.message.game_input_fields
            );
            res.data.message.game_input_fields.map((field, i) => {
              value.push({
                label: field.value,
                input_value: "",
              });
            });
            setDefaultFields(value);
            console.log("default__ ", value);
            console.log("DFF", res.data.message.game_input_fields);
            setGameAvailable(true);
            setDefaultComponent(true);
            LoadGameInputs(
              res.data.message.game_input_fields,
              res.data.message
            );
          }
          setTimeout(() => {
            UpdateMailToLinks();
          }, 10);
          setLoad(false);
        }
      })
      .catch((err) => {
        console.log(err);
        if (
          err.response &&
          err.response.data.message === "Your team has not started the pod yet"
        ) {
          setPodNotStarted(true);
          if (err.response.data.podBody) {
            setPodId(err.response.data.podBody.podData[0][0]._id);
          }
          setLoad(false);
        } else {
          if (
            err.response &&
            err.response.data &&
            err.response.data.teamRequired
          ) {
            window.location.href = "/drawerpods";
          } else {
            if (
              err.response.data.message === "Access code incorrect" ||
              err.response.data.message ===
                "User email or access code is not correct"
            ) {
              setIsAccessCodeRequired(true);
            } else if (accessToken === null) {
              if (gameId === undefined) {
                window.location.href = "/auth";
              } else {
                window.location.href = `/auth:${gameId}/${0}`;
              }
            } else if (err.response.status === 403) {
              localStorage.removeItem("accesstoken");
              if (gameId === undefined) {
                window.location.href = "/auth";
              } else {
                window.location.href = `/auth:${gameId}/${0}`;
              }
            }
            setTimeout(() => {
              setLoad(false);
            }, 4000);
          }
        }
        // localStorage.removeItem("accesstoken");

        // if (gameId === undefined) {
        //   window.location.href = "/auth";
        // } else {
        //   window.location.href = `/auth:${gameId}`;
        // }
      });
    // }
  };
  const LoadGameInputs = async (data, gameData) => {
    setLoad(true);
    setDefaultComponent(false);
    console.log("some data", data);
    let config2;
    if (!gameData.login_required && gameData.accessToken) {
      localStorage.setItem("accesstoken", gameData.accessToken);
      if (accessCode) {
        config2 = {
          headers: {
            Authorization: `Bearer ${gameData.accessToken}`,
            accesscode: accessCode,
          },
        };
      } else {
        config2 = {
          headers: {
            Authorization: `Bearer ${gameData.accessToken}`,
          },
        };
      }
    }
    await axios
      .get(
        `${APIURI}/web-game/get-inputs/${gameId}`,
        config2 ? config2 : config
      )
      .then((res) => {
        console.log("submit ressss", res.data.message);
        if (res.data.message[0].user_id) {
          setCustomComponent(true);
          setFields(res.data.message);
          setGameFields(res.data.message[0].input_fields);
          setUserInputField(res.data.message[0].input_fields);
          console.log("fields", res.data.message);
          console.log(111);
          let differnce = data.length - res.data.message[0].input_fields.length;
          console.log("difference", differnce);
          setLoad(false);
          let value = [...res.data.message[0].input_fields];
          // let value = [...gameFields];
          let value1 = [];
          value.map((field, i) => {
            value1.push({ label: field.label, input_value: field.input_value });
            console.log(value);
          });
          // setGameFields(value);
          let value2 = [];

          data.map((res, i) => {
            console.log(res);
            if (value[i] !== undefined) {
              value2.push({
                label: value[i].label,
                input_value: value[i].input_value,
              });
            } else {
              value2.push({ label: "", input_value: "" });
            }
          });
          setDefaultFields(value2);
          let gamefieldvalue = [...res.data.message[0].input_fields];
          let lengthinput_field = res.data.message[0].input_fields.length;
          data.map((res, i) => {
            {
              console.log("ressss", res);
            }
            if (lengthinput_field < data.length) {
              gamefieldvalue.push({ label: "", input_value: "" });
            }
            lengthinput_field++;
          });
          setGameFields(gamefieldvalue);
          // const count = res.data.message[0].input_fields.length;
          // for (let index = 1; index < count; index++) {
          //   handleAddGameInputs();
          // }
        } else {
          setDefaultComponent(true);
          setFields(res.data.message);
          console.log(222, res.data.message);
          let gamefieldvalue = [];
          data.map((res, i) => {
            {
              console.log("ress", res);
            }
            gamefieldvalue.push({ label: "", input_value: "" });
          });
          setGameFields(gamefieldvalue);
          console.log("rahul", gamefieldvalue);
          // const count = res.data.message.length;
          // for (let index = 0; index <= count; index++) {
          //   handleAddGameInputs(count);
          //   console.log(count, index);
          // }
          setLoad(false);
        }
      });
  };
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClosePopover = () => {
    setTooglePopover(false);
  };
  function handleTakePhoto(dataUri) {
    // Do stuff with the photo...
    console.log("takePhoto", dataUri);
  }
  function handleValidateCode() {
    setIncorrectCodeError("");
    axios
      .post(
        `${APIURI}/web-game/validateAccessCode/${gameId}`,
        { accessCode: accessCode },
        config
      )
      .then((res) => {
        console.log(res);
        if (res.data.validate) {
          setIsAccessCodeRequired(false);
          setLoad(true);
          localStorage.setItem("accessCode", accessCode);
          // window.location.reload();
          setCallApiAgain(callApiAgain + 1);
        } else {
          setIncorrectCodeError("Invalid code. Codes are case sensitive.");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const NoQRComponent = () => {
    if (isAccessCodeRequired) {
      return (
        <Dialog
          open={isAccessCodeRequired}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="access--code--modal"
        >
          <DialogTitle
            id="alert-dialog-title"
            className="access--code--required"
          >
            {"Access Code Required"}
          </DialogTitle>
          <DialogContent>
            <div className="Location-Footer-Content">
              <div className="loc-enter">
                <label className="access--code--required--label">
                  Enter Access Code
                </label>
                <input
                  type="text"
                  defaultValue={accessCode}
                  placeholder="TYPE HERE"
                  onChange={(e) => {
                    setAccessCode(e.target.value);
                    setIncorrectCodeError("");
                  }}
                  style={{ marginBottom: "10px" }}
                />
              </div>
              <p style={{ color: "#dc3545", margin: "0", fontWeight: "700" }}>
                {incorrectCodeError ? incorrectCodeError : ""}
              </p>
            </div>
          </DialogContent>
          <DialogActions style={{ padding: "16px 24px" }}>
            <Button
              className="dialog-btn-dismiss"
              autoFocus
              variant="contained"
              style={{ width: "100%" }}
              onClick={() => handleValidateCode()}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      );
    } else if (podNotStarted) {
      {
        /* not started pod  */
      }
      return (
        <Dialog
          open={podNotStarted}
          // onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
            {"START YOUR TIME"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              className="dialog-desc"
            >
              Your team has not clicked start. Return to your team homepage
              below, click start, then rescan this code to play.
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ padding: "2em" }}>
            <Button
              className="dialog-btn-dismiss"
              autoFocus
              variant="contained"
              onClick={() => {
                window.location.href = `/pods:${podId}`;
              }}
            >
              TEAM HOMEPAGE
            </Button>
          </DialogActions>
        </Dialog>
      );
    } else
      return (
        <Dialog
          open={true}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Did you skip the Game QR Code?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Oops! looks like you’ve skipped the previous Game QR Code, Please
              scan the Game QR Code to continue.!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={handleClose} color="dark" autoFocus variant='contained'>
            Go Back
          </Button> */}
            {/* <Camera onTakePhoto = { (dataUri) => { handleTakePhoto(dataUri); } }/> */}
          </DialogActions>
        </Dialog>
      );
  };

  if (load) {
    return <Loader />;
  }
  function handleGameInputs(i, event, key) {
    console.log(i);
    const values = [...gameFields];
    console.log("this ", gameFields);
    values[i].label = key;
    values[i].input_value = event.target.value;
    // console.log(values);
    setGameFields(values);
  }
  function handleAddGameInputs(count) {
    const values = [...gameFields];
    if (count !== 1) {
      values.push({ label: "", input_value: "" });
    }
    console.log("values......", values);
    setGameFields(values);
  }
  const handleCheckBox = (e) => {
    console.log("Checked", e.target.checked);
    if (e.target.checked) {
      setIsCheckedTrue(true);
      let cValue = game[0].game_input_fields;
      let value = [];
      cValue.map((field, i) => {
        value.push({ label: field.value, input_value: field.defaultValue });
        console.log(value);
      });
      console.log("value if condition", value);
      setGameFields(value);
      setDefaultFields(value);
    } else if (!e.target.checked) {
      setIsCheckedTrue(false);
    }
    // else if(!e.target.checked){
    //   let cValue=userInputField
    //   let value=[];
    //   cValue.map((field,i)=>{
    //     value.push({label:field.label,input_value:field.input_value});
    //   });
    //   setGameFields(value);
    //   setDefaultFields(value);
    //   console.log(" valueee else ",value)
    // }
    // console.log("game fields",gameFields)
    // console.log("default fields",defaultFields)
    // if (e.target.checked && isDefaultComponent) {
    //   let value = [...gameFields];
    //   value = [];
    //   fields.map((field, i) => {
    //     value.push({ label: field.key, input_value: field.defaultValue });
    //     console.log(value);
    //   });
    //   setGameFields(value);
    //   setDefaultFields(value);
    // } else if (e.target.checked && isCustomComponent) {
    //   let cValue = game[0].game_input_fields;
    //   let value = [];
    //   cValue.map((field, i) => {
    //     value.push({ label: field.value, input_value: field.defaultValue });
    //     console.log(value);
    //   });
    //   setGameFields(value);
    //   setDefaultFields(value);
    // } else if (!e.target.checked && isDefaultComponent) {
    //   let value = [...gameFields];
    //   value = [];
    //   fields.map((field, i) => {
    //     value.push({ label: "", input_value: "" });
    //     console.log(value);
    //   });
    //   // setGameFields(value);
    //   setDefaultFields(value);
    // } else if (!e.target.checked && isCustomComponent) {
    //   setGameFields(fields[0].input_fields);
    // }
  };
  async function DOME() {
    let elements = document.getElementsByClassName("tooltip");
    console.log("Elements:", elements[0]);
    let myFunction = function (e) {
      e.preventDefault();
      let attribute = this.getElementsByTagName("span");
      console.log(attribute);
      setTooglePopover(true);
      setTooglePopoverMessage(attribute[0].innerHTML);
      this.removeEventListener("click", myFunction);
    };
    if (elements.length > 1) {
      for (let i = 0; i < elements.length; i++) {
        await elements[i].addEventListener("click", myFunction, false);
      }
    } else if (elements[0] === undefined) {
      console.log("Else UNDEFINED 123");
    } else {
      console.log("Else 123");
      await elements[0].addEventListener("click", myFunction, false);
    }
  }
  DOME();
  function ContentTypeComponent(data) {
    // const data = props.content;
    if (data.key === "Text") {
      let desc = data.value;
      game[0].game_popup.map((te, i) => {
        if (te.icon) {
          desc = desc.replaceAll(
            te.identifier,
            `<span class="tooltip" id='tooltip-${i}'><img src="${te.icon}" alt="PopupIcon" /><span>${te.title}</span></span>`
          );
        } else {
          desc = desc.replaceAll(
            te.identifier,
            `<span class="tooltip" id='tooltip-${i}'><img src ="/assets/PopupIcon.svg" alt="PopupIcon" /><span>${te.title}</span></span>`
          );
        }
        handleTooltip();
      });
      return renderDesc(desc);
    } else if (data.key === "Audio") {
      return (
        // <audio
        //   style={{ width: "100%", marginBottom: "1em" }}
        //   controls
        //   controlsList="nofullscreen nodownload noremoteplayback noplaybackrate"
        //   src={data.value}
        // />
        <div className="location-media">
          <Audio content={data.value} />
        </div>
      );
    } else if (data.key === "Image") {
      return (
        <div className="location-media">
          <img
            style={{
              height: "100%",
              width: "100%",
              maxWidth: "100%",
            }}
            src={data.value}
            alt="SceneFeaturedImage"
          />
        </div>
      );
    } else {
      return (
        <div className="location-media">
          <Video content={data.value} />
        </div>
      );
    }
  }
  if (isGameAvailable) {
    return (
      <React.Fragment>
        {/* <Dialog
          open={isAccessCodeRequired}
          // onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="access--code--modal"
        >
          <DialogTitle id="alert-dialog-title" 
            className="access--code--required">
            {"Access Code Required"}
          </DialogTitle>
          <DialogContent>
            <div className="Location-Footer-Content">
              <div className="loc-enter">
                <label className="access--code--required--label">Enter Access Code</label>
                <input
                  type="text"
                  defaultValue={accessCode}
                  placeholder="TYPE HERE"
                  onChange={(e) => {
                    setAccessCode(e.target.value);
                    setIncorrectCodeError("");
                  }}
                  style={{ marginBottom: "10px" }}
                />
              </div>
              <p style={{ color: "#dc3545", margin: "0", fontWeight: "700" }}>
                {incorrectCodeError ? incorrectCodeError : ""}
              </p>
            </div>
          </DialogContent>
          <DialogActions style={{ padding: "16px 24px" }}>
            <Button
              className="dialog-btn-dismiss"
              autoFocus
              variant="contained"
              style={{ width: "100%" }}
              onClick={() => handleValidateCode()}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog> */}
        <div className="Location-Story" style={{ paddingBottom: "100px" }}>
          <Dialog
            open={togglePopover}
            onClose={handleClosePopover}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="PopOver"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <div
                  dangerouslySetInnerHTML={createMarkup(togglePopoverMessage)}
                />{" "}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClosePopover}
                className="dialog-btn-dismiss"
                style={{ marginBottom: "2.2rem" }}
                autoFocus
                variant="contained"
              >
                Okay
              </Button>
              {/* <Camera onTakePhoto = { (dataUri) => { handleTakePhoto(dataUri); } }/> */}
            </DialogActions>
          </Dialog>
          <div className="Location-header">
            <div className="container" style={{ position: "relative" }}>
              {game.map((data, index) => {
                return (
                  <img
                    key={index}
                    src={data.image}
                    alt={data.title}
                    style={{ width: "100.5%" }}
                  />
                );
              })}
              {/* <button>
              <ArrowDownwardIcon />
            </button> */}
            </div>
            {game.map((data, index) => (
              <h2 style={{ display: "none" }}>{data.title}</h2>
            ))}
            {/* <span>NEXT?</span> */}
          </div>
          <div className="Location-container" id="location-container">
            {GameDescription.map((data) => {
              return (
                <div key={data._id}>
                  {/* <ContentTypeComponent content={data} /> */}
                  {ContentTypeComponent(data)}
                </div>
              );
            })}
          </div>
          <div className="Location-footer">
            {console.log("fields", fields)}
            {console.log("fields default123", defaultFields)}
            {console.log("game_input_fields_input", gameInputFields)}
            <div className="Location-Footer-Content">
              {gameInputFields.map((field, idx) => {
                return (
                  <div className="loc-enter" key={`${field}-${idx}`}>
                    <label>{field.key}</label>
                    <input
                      type="text"
                      defaultValue={defaultFields[idx].input_value}
                      placeholder={field.value ? field.value : "TYPE HERE"}
                      value={gameFields[idx] ? gameFields[idx].input_value : ""}
                      onChange={(e) => handleGameInputs(idx, e, field.key)}
                    />
                  </div>
                );
              })}
            </div>
            {/* {isDefaultComponent && fields.length !== 0 ? (
              <div className="Location-Footer-Content">
                {fields.map((field, idx) => {
                  // console.log("Game Index Fields", idx);
                  // console.log("Default  Fields", defaultFields);
                  // console.log("Game Fields", gameFields);
                  console.log(fields)
                  return (
                    <div className="loc-enter" key={`${field}-${idx}`}>
                      <label>{field.key}</label>
                      <input
                        type="text"
                        defaultValue={defaultFields[idx].input_value}
                        placeholder={field.value}
                        onChange={(e) => handleGameInputs(idx, e, field.key)}
                      />
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="Location-Footer-Content">
                {fields.map((field, idx) =>
                  field.input_fields.map((data, idxx) => {
                    console.log("Game Index Fields", idxx);
                    console.log("Default  Fields", defaultFields);
                    console.log("Game Fields", gameFields);
                    console.log(fields)
                    return (
                      <div className="loc-enter" key={`${field}-${idxx}`}>
                        <label>{data.label}</label>
                        {console.log(gameFields[idxx])}
                        {console.log(fields.input_fields)}
                        <input
                          style={{ height: "18px" }}
                          type="text"
                          name={`input${idx}`}
                          // defaultValue={data.input_value}
                          defaultValue={gameFields[idxx]?gameFields[idxx].input_value:""}
                          // defaultValue={solutionAnswer[index].answer}
                          placeholder="TYPE-HERE"
                          onChange={(e) =>
                            handleGameInputs(idxx, e, data.label)
                          }
                        />
                      </div>
                    );
                  })
                )}
              </div>
            )} */}
            {/* <TextField id="outlined-basic" label="Enter Coffee Shop" variant="outlined" onChange={(e) => setShop(e.target.value)}/> <br />
                    <TextField id="outlined-basic" label="Enter Restaurant" variant="outlined" onChange={(e) => setRest(e.target.value)}/> <br /> */}
            {game[0].game_input_fields.length !== 0 ? (
              <FormControlLabel
                className="my-label"
                control={
                  <Checkbox
                    className="my-checkbox"
                    onChange={(e) => handleCheckBox(e)}
                    name="checkedB"
                    color="dark"
                  />
                }
                label={checkBoxText}
              />
            ) : (
              <></>
            )}
            {console.log("123456", game)}
            {game[0].game_input_fields.length !== 0 ? (
              <Button
                className="btn-location"
                variant="contained"
                onClick={(e) => handleLocation(e)}
              >
                SUBMIT
              </Button>
            ) : (
              <></>
            )}
            {game.map((field) => {
              return <p>{field.recommendations}</p>;
            })}
            {/* <CaptureImages frames={frames} /> */}
          </div>
        </div>

        {/* <div style={{ marginTop: "4em" }}>
          {isLoginRequired ? <LogoutComponent /> : ""}
        </div> */}

        <Footer
          isLoginRequired={isLoginRequired}
          tutorialLink={`/tutorial:${gameId}`}
        />

        {/* push nofication  */}
        <Dialog
          open={pushNotificationModal}
          onClose={() => {
            setPushNotificationModal(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="PopOver"
        >
          {/* <DialogTitle id="alert-dialog-title">Message</DialogTitle> */}
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {/* <div dangerouslySetInnerHTML={createMarkup(pushNotificationData)} /> */}
              <div>
                <p>{pushNotificationData}</p>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setPushNotificationModal(false);
              }}
              style={{ marginBottom: "2.2rem" }}
              className="dialog-btn-dismiss"
              autoFocus
              variant="contained"
            >
              Okay
            </Button>
            {/* <Camera onTakePhoto = { (dataUri) => { handleTakePhoto(dataUri); } }/> */}
          </DialogActions>
        </Dialog>

        {/* not started pod  */}
        <Dialog
          open={podNotStarted}
          // onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
            {"START YOUR TIME"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              className="dialog-desc"
            >
              Your team has not clicked start. Return to your team homepage
              below, click start, then rescan this code to play.
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ padding: "2em" }}>
            <Button
              className="dialog-btn-dismiss"
              autoFocus
              variant="contained"
              onClick={() => {
                window.location.href = `/pods:${podId}`;
              }}
            >
              TEAM HOMEPAGE
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  } else {
    return NoQRComponent();
  }
}
export default withRouter(LocationStory);

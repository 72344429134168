import React from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import axios from "axios";
import { APIURI, SOCKET_URI } from "../config";
import { useState, useEffect, useContext } from "react";
import { withRouter, useHistory } from "react-router-dom";
import LogoutComponent from "./Logout";
import Swal from "sweetalert2";
import createMarkup from "../utils/CreateMarkup";
import ReactAudioPlayer from "react-audio-player";
import Audio from "../utils/Audios/Audio";
import Loader from "../utils/Loader";
import Video from "../utils/Videos/Video";
import useAudioPlayer from "../utils/Audios/useAudioPlayer";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { Context } from "../store/Store";
import CaptureImages from "./CaptureImages";
import io from "socket.io-client";
import Footer from "./Footer";
import Hints from "./Hints";
const socketNotification = io(`${SOCKET_URI}/notification`, {
  reconnection: true, // Enable automatic reconnection
  reconnectionAttempts: 10, // Number of reconnection attempts
  reconnectionDelay: 2000, // Delay between reconnection attempts (in milliseconds)
});
// import { useContext } from "react";

function Solutions(props) {
  const sceneID = props.match.params.sceneID;
  const gameId = props.match.params.gameID;
  localStorage.setItem("SceneID", sceneID);
  // console.log({
  //     gameId, sceneID
  // });
  const history = useHistory();

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  // const [playing,setPlaying]=useContext(Context);
  const [answer, setAnswer] = useState("");
  const [openHintModal, setOpenHintModal] = useState(false);
  const [load, setLoad] = useState(true);
  const [open, setOpen] = useState(false);
  const [frames, setFrames] = useState([]);
  // const [isAnswerWrong, setIsAnswerWrong] = useState(false);
  const [sceneDetails, setSceneDetails] = useState([]);
  const [description, setDescription] = useState("");
  const [SceneDescription, setSceneDescription] = useState([]);
  const [solMSG, setSolMSG] = useState("");
  const [wronganswer, setWrongAnswer] = useState([]);
  const [noQR, setNoQR] = useState(false);
  const [isInputActive, setIsInputActive] = useState(false);
  const [IsOutofOrder, setIsOutofOrder] = useState(false);
  const [IsGameComplete, setIsGameComplete] = useState(false);
  const [hasPopover, setHasPopover] = useState("noTooltip");
  const [togglePopover, setTooglePopover] = useState(false);
  const [togglePopoverMessage, setTooglePopoverMessage] = useState([]);
  const [isOnChangeRun, setIsOnChangeRun] = useState(false);
  const [togglePopoverArr, setTooglePopoverArr] = useState([]);
  const [noScene, setNoScene] = useState(false);
  const [buttonLoad, setButtonLoad] = useState(false);
  const accessToken = localStorage.getItem("accesstoken");
  const [hideContinueBtn, setHideContinueBtn] = useState("");
  const [emailCollectionField, setEmailCollectionField] = useState("");
  const [isFirstSceneRequired, setIsFirstSceneRequired] = useState("");
  const [isLoginRequired, setIsLoginRequired] = useState(false);
  const [actionButtonLoad, setActionButtonLoad] = useState(true);
  const [isSceneCompleteForTeam, setIsSceneCompleteForTeam] = useState(false);
  const [isPodActive, setIsPodActive] = useState(false);
  const [podFrames, setPodFrames] = useState([]);
  const [podId, setPodId] = useState("");
  const [pushNotificationModal, setPushNotificationModal] = useState(false);
  const [podNotificationsOnTime, setPodNotificationsOnTime] = useState([]);
  const [pushNotificationData, setPushNotificationData] = useState("");
  const [podNotStarted, setPodNotStarted] = useState(false);
  const [hasHints, setHasHints] = useState(true);

  const [solutionAnswer, setSolutionAnswer] = useState([
    { _id: "", answer: "" },
  ]);
  const [totalAnswers, setTotalAnswers] = useState([]);
  const [correctAnswers, setCorrectAnswers] = useState([]);
  const [wrongAnswers, setWrongAnswers] = useState([]);
  const [ansCount, setansCount] = useState(0);
  const [arrErrorMessage, setarrErrorMessage] = useState([]);
  const [arrErrorMessageCount, setarrErrorMessageCount] = useState([]);
  const [nextScene, setNextScene] = useState(0);
  const [onErrorId, setOnErrorId] = useState([]);
  const [isAccessCodeRequired, setIsAccessCodeRequired] = useState(false);
  const [accessCode, setAccessCode] = useState("");
  const [incorrectCodeError, setIncorrectCodeError] = useState("");
  const [callApiAgain, setCallApiAgain] = useState(0);
  const [email, setEmail] = useState("");
  let config;
  if (accessCode) {
    config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        accesscode: accessCode,
      },
    };
  } else {
    config = {
      headers: { Authorization: `Bearer ${accessToken}` },
    };
  }
  let submittedAnswerCount = 0;

  const renderDesc = (desc) => {
    if (desc.includes("#cameraSection")) {
      return (
        <>
          <div
            className="desc-Text-solution"
            dangerouslySetInnerHTML={createMarkup(
              desc.split("#cameraSection")[0]
            )}
          />
          {/* <div className="desc-Text-solution"> */}
          <CaptureImages
            color="#000"
            frames={
              isPodActive ? (podFrames.length > 0 ? podFrames : frames) : frames
            }
          />
          {/* </div> */}
          {desc.split("#cameraSection")[1] ? (
            <div
              className="desc-Text-solution"
              dangerouslySetInnerHTML={createMarkup(
                `<p>${desc.split("#cameraSection")[1]}`
              )}
            />
          ) : (
            ""
          )}
        </>
      );
    } else {
      return (
        <div
          className="desc-Text-solution"
          dangerouslySetInnerHTML={createMarkup(desc)}
        />
      );
    }
  };

  function UpdateMailToLinks() {
    let allA = document.getElementsByTagName("A");
    for (let i = 0, len = allA.length; i < len; i++) {
      console.log("mailto: llink for loop", allA[i]);
      if (allA[i].href.includes("mailto:")) {
        allA[i].className = "style--for--mailto--location";
      }
    }
  }
  function handelConfig() {
    if (accessCode) {
      config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          accesscode: accessCode,
        },
      };
    } else {
      config = {
        headers: { Authorization: `Bearer ${accessToken}` },
      };
    }
  }

  function handleValidateCode() {
    console.log("handle");
    setIncorrectCodeError("");
    axios
      .post(
        `${APIURI}/web-game/validateAccessCode/${gameId}`,
        { accessCode: accessCode },
        config
      )
      .then((res) => {
        console.log(res);
        if (res.data.validate) {
          setIsAccessCodeRequired(false);
          setLoad(true);
          localStorage.setItem("accessCode", accessCode);
          // window.location.reload();
          setCallApiAgain(callApiAgain + 1);
        } else {
          setIncorrectCodeError("Invalid code. Codes are case sensitive.");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function handleTooltip() {
    let elements = document.getElementsByClassName("tooltip");
    let arr = await togglePopoverArr;
    let myFunction = function (e, i, acID) {
      e.preventDefault();
      console.log(e, i, arr[0][acID]);
      setTooglePopover(true);
      setTooglePopoverMessage(arr[0][acID]);
    };

    for (let i = 0; i < elements.length; i++) {
      let actionBtn = elements[i];
      let actionBtnId = actionBtn.id.split("-");
      // console.log(actionBtnId[1]);
      actionBtn.addEventListener(
        "mousedown",
        (e) => myFunction(e, i, actionBtnId[1]),
        false
      );
    }
  }

  function ContentTypeComponent(data) {
    // const data = props.content;
    if (data.key === "Text") {
      let desc = data.value;
      console.log("Scene Details", sceneDetails);
      sceneDetails[0].inputFields.map((te) => {
        for (const [key, value] of Object.entries(te)) {
          // console.log(key, value);
          desc = desc.replaceAll("#" + key, value);
        }
      });
      sceneDetails[0].scene_popup.map((te, i) => {
        if (te.icon) {
          desc = desc.replaceAll(
            te.identifier,
            `<span class="tooltip" id='tooltip-${i}'><img src="${te.icon}" alt="PopupIcon" /><span>${te.title}</span></span>`
          );
        } else {
          desc = desc.replaceAll(
            te.identifier,
            `<span class="tooltip" id='tooltip-${i}'><img src ="/assets/PopupIcon.svg" alt="PopupIcon" /><span>${te.title}</span></span>`
          );
        }
        handleTooltip();
      });
      return renderDesc(desc);
    } else if (data.key === "Audio") {
      let yyy = document.getElementById(`audio${data._id}`);
      if (yyy !== null && yyy.currentTime) {
        // console.log("current time",yyy.currentTime)
        window.audioState[data._id] = yyy.currentTime;
        // console.log(window.audioState[data._id])
      }
      return (
        // <audio
        //   style={{ width: "100%", marginBottom: "1em" }}
        //   controls
        //   controlsList="nofullscreen nodownload noremoteplayback noplaybackrate"
        //   src={data.value}
        // />
        <div className="solution-media">
          {/* {console.log(playing)} */}
          <Audio content={data.value} id={data._id} />
          {/* <AudioPlayer
          aria-hidden="false"
    autoPlay
    src={data.value}
    onPlay={e => console.log("onPlay")}
    // other props here
  /> */}
        </div>
      );
    } else if (data.key === "Image") {
      return (
        <img
          className="solution-media"
          style={{
            width: "100%",
            height: "100%",
          }}
          src={data.value}
          alt="SceneFeaturedImage"
        />
      );
    } else {
      return (
        <div className="solution-media">
          <Video
            style={{
              width: "100%",
              height: "100%",
              marginBottom: "1em",
            }}
            content={data.value}
          />
        </div>
      );
    }
  }

  function handleAddSolutionAnswers(totalAnscount) {
    const values = [];
    totalAnscount.map((res) => {
      values.push({ _id: res._id, answer: "" });
    });
    setSolutionAnswer(values);
  }

  function removeWrongAnsKeys(key) {
    let arry = [...wrongAnswers];
    console.log(arry, "after");
    if (arry.includes(key)) {
      arry.splice(arry.indexOf(key), 1);
    }
    console.log(arry, "before");
    setWrongAnswers(arry);
  }

  function handleSolutionInputs(i, event, key) {
    event.preventDefault();
    // const errorId = [...onErrorId];
    // if (!errorId.includes(key)) {
    //   errorId.push(key);
    // }
    // setOnErrorId(errorId);
    // setIsInputActive(false);
    // if (wrongAnswers.length > 0) {
    //   const wrong = wrongAnswers;
    //   if (wrong[i] === key) {
    //     wrong.pop();
    //     setWrongAnswers(wrong);
    //   }
    // }
    // console.log(i)
    // console.log(key)
    removeWrongAnsKeys(key);
    const values = [...solutionAnswer];
    values[i]._id = key;
    values[i].answer = event.target.value;
    // console.log(values);
    setSolutionAnswer(values);
    setIsOnChangeRun(true);
  }
  function handleRedCrossClick(i, e, key) {
    e.preventDefault();
    console.log("red cross clicked!!");
    removeWrongAnsKeys(key);
    // const errorId = [...onErrorId];
    // if (!errorId.includes(key)) {
    //   errorId.push(key);
    // }
    // setOnErrorId(errorId);
    const values = [...solutionAnswer];
    values[i] = { ...values[i], answer: "" };
    // values[i]._id = key;
    // console.log(values[i].answer);
    // values[i].answer = "";
    console.log(values);
    setSolutionAnswer(values);
    setIsOnChangeRun(true);
  }

  const handleFocus = (i, event, key) => {
    event.preventDefault();
    if (wrongAnswers.length > 0) {
      const wrong = wrongAnswers;
      // if (wrong[i] === key) {
      //   delete wrong[i];
      //   console.log("wrong in handle focus", wrong);
      //   setWrongAnswers(wrong);
      // }
    }
  };
  let correctArr = [];
  let wrongArr = [];

  let errorMessage = [];
  let errorMessageCount = [];

  const handleAnswerValidate = async (e) => {
    // setOnErrorId([]);
    setLoad(true);
    setButtonLoad(true);
    e.preventDefault();
    const dataAns = solutionAnswer;
    if (dataAns[0].answer === "") {
      setButtonLoad(false);
    }
    if (dataAns[0].answer !== "") {
      wrongArr = [];
      console.log(totalAnswers);
      console.log(dataAns);
      if (totalAnswers.length > 0) {
        console.log(dataAns, "406");
        let ansTrimArry = [];
        dataAns.forEach((ans) => {
          ansTrimArry.push({
            _id: ans._id,
            answer: ans.answer.trim(),
          });
        });
        let answerBody = { list: ansTrimArry };
        console.log(ansTrimArry);
        axios
          .post(
            `${APIURI}/web-answer/validateLoop/${sceneID}`,
            answerBody,
            config
          )
          .then((res) => {
            submittedAnswerCount = ansTrimArry.length;
            console.log(res);

            // compaire the id for validation of user entered answer and response from api
            res.data.validatedList.forEach((validationFromApi, index) => {
              ansTrimArry.forEach((userAnswer) => {
                if (validationFromApi._id === userAnswer._id) {
                  if (res.data.validatedList[index].Validation) {
                    correctArr.push(res.data.validatedList[index]._id);
                    console.log(correctArr);
                  } else {
                    wrongArr.push(res.data.validatedList[index]._id);
                    console.log(
                      "lengthhhh:------ ",
                      arrErrorMessageCount.length
                    );
                  }
                }
              });
            });

            console.log("Submitted Count: ", submittedAnswerCount, ansCount);
            if (ansCount === submittedAnswerCount) {
              console.log("Wrong Answers", wrongArr);
              setWrongAnswers(wrongArr);
              setCorrectAnswers(correctArr);
              if (wrongArr.length === 0) {
                console.log("NEXT SCENE ID: ", nextScene);
                setButtonLoad(false);
                if (nextScene !== 0 && nextScene !== null) {
                  axios
                    .get(`${APIURI}/web-answer/complete/${sceneID}`, config)
                    .then((res) => {
                      console.log(
                        "move to next screen called here is the response",
                        res
                      );
                      window.location.href = `/solution:${gameId}/${nextScene}`;
                    });
                  setButtonLoad(false);
                } else {
                  axios
                    .get(`${APIURI}/web-answer/complete/${sceneID}`, config)
                    .then((res) => {
                      console.log(
                        "move to next screen called here is the response",
                        res
                      );
                      window.location.href = `/congrats:${sceneID}`;
                      setButtonLoad(false);
                    });
                }
              }
              setButtonLoad(false);
            }
            setButtonLoad(false);
          });
        setLoad(false);
      } else {
        console.log("No answers");
        setLoad(false);
        setButtonLoad(false);
        if (nextScene !== 0 && nextScene !== null) {
          axios
            .get(`${APIURI}/web-answer/complete/${sceneID}`, config)
            .then((res) => {
              console.log(
                "move to next screen called here is the response",
                res
              );
              window.location.href = `/solution:${gameId}/${nextScene}`;
            });
        } else {
          axios
            .get(`${APIURI}/web-answer/complete/${sceneID}`, config)
            .then((res) => {
              console.log(
                "move to next screen called here is the response",
                res
              );
              window.location.href = `/congrats:${sceneID}`;
            });
        }
      }
    }
    setLoad(false);
    setIsOnChangeRun(false);
  };

  const handleAnswerValidateContinue = async () => {
    setLoad(true);
    wrongArr = [];
    console.log("No answers");
    setLoad(false);
    setButtonLoad(false);
    if (nextScene !== 0 && nextScene !== null) {
      axios
        .get(`${APIURI}/web-answer/complete/${sceneID}`, config)
        .then((res) => {
          console.log("move to next screen called here is the response", res);
          window.location.href = `/solution:${gameId}/${nextScene}`;
        });
    } else {
      axios
        .get(`${APIURI}/web-answer/complete/${sceneID}`, config)
        .then((res) => {
          console.log("move to next screen called here is the response", res);
          window.location.href = `/congrats:${sceneID}`;
        });
    }
    setLoad(false);
    setIsOnChangeRun(false);
  };

  const handleEmailCollection = async (e) => {
    setLoad(true);
    setButtonLoad(true);
    axios
      .post(
        `${APIURI}/web-answer/addEmailTosceneHistory/${sceneID}`,
        { email: email },
        config
      )
      .then((res) => {
        console.log(res);
        console.log("No answers");
        setButtonLoad(false);
        if (nextScene !== 0 && nextScene !== null) {
          // console.log("No answers Block IF");
          axios
            .get(`${APIURI}/web-answer/complete/${sceneID}`, config)
            .then((res2) => {
              console.log(
                "move to next screen called here is the response",
                res2
              );
              window.location.href = `/solution:${gameId}/${nextScene}`;
            });
          // window.location.href = `/solution:${gameId}/${nextScene}`;
        } else {
          // console.log("No answers Block Else");
          axios
            .get(`${APIURI}/web-answer/complete/${sceneID}`, config)
            .then((res) => {
              console.log(
                "move to next screen called here is the response",
                res
              );
              window.location.href = `/congrats:${sceneID}`;
            });
          // window.location.href = `/congrats:${sceneID}`;
        }
        setButtonLoad(false);
      });
  };

  const LoadScene = async () => {
    setLoad(true);
    setActionButtonLoad(true);
    if (sceneID === undefined) {
      setNoQR(true);
    } else {
      await axios
        .get(`${APIURI}/web-scene/view/${sceneID}`, config)
        .then((res) => {
          let arr = [];
          if (res.data.podBody && res.data.podBody.podStatus === "active") {
            if (res.data.podBody && res.data.podBody.podData[0][0]._id) {
              socketNotification.emit(
                "joinRoom",
                `${res.data.podBody.podData[0][0]._id}`
              );
            } else {
              console.error(
                "res.data.podBody or res.data.podBody.podData[0][0]._id is not properly defined."
              );
            }
            // socketNotification.emit(
            //   "joinRoom",
            //   `${res.data.podBody.podData[0][0]._id}`
            // );
            setIsPodActive(true);
            setPodId(res.data.podBody.podData[0][0]._id);
            setPodFrames(res.data.podBody.podData[0][0].Frames);
          }
          arr.push(res.data.message);
          setFrames(res.data.message.Frames);
          setHasHints(res.data.hasHints);
          console.log("SceneDetails", res.data.message);
          localStorage.setItem("SceneDetails", res.data.message);
          setSceneDetails(arr);
          let config2;
          setIsLoginRequired(res.data.message.login_required);

          localStorage.setItem(
            "isLoginRequired",
            res.data.message.login_required
          );
          if (
            !res.data.message.login_required &&
            res.data.message.accessToken
          ) {
            localStorage.setItem("accesstoken", res.data.message.accessToken);
            if (accessCode) {
              config2 = {
                headers: {
                  Authorization: `Bearer ${res.data.message.accessToken}`,
                  accesscode: accessCode,
                },
              };
            } else {
              config2 = {
                headers: {
                  Authorization: `Bearer ${res.data.message.accessToken}`,
                },
              };
            }
          }
          setNextScene(res.data.message.nextSceneId);
          setHideContinueBtn(res.data.message.hide_continue_button);
          setEmailCollectionField(res.data.message.email_collection);
          let desc = res.data.message.description;
          let solutionMSG = res.data.message.solution_screen_message;
          res.data.message.inputFields.map((te) => {
            for (const [key, value] of Object.entries(te)) {
              console.log(key, value);
              desc = desc.replaceAll("#" + key, value);
              solutionMSG = solutionMSG.replaceAll("#" + key, value);
            }
          });
          console.log(desc);
          setDescription(desc);
          setSolMSG(solutionMSG);
          setIsOutofOrder(res.data.message.isOutOfOrder);
          setIsGameComplete(res.data.message.isGameComplete);
          setIsFirstSceneRequired(res.data.message.first_scene_required);
          setSceneDescription(res.data.message.scene_description);

          localStorage.setItem(
            "sceneDetails",
            JSON.stringify(res.data.message)
          );
          // console.log(res.data.message);
          let arrPop = togglePopoverArr;
          arrPop.push(res.data.message.scene_popup.map((te) => te.description));
          setTooglePopoverArr(arrPop);
          handleTooltip();
          console.log("List of all Answer:", res.data.allAnswer);

          setTotalAnswers(res.data.allAnswer);
          const count = res.data.allAnswer.length;
          setansCount(count);
          // for (let i = 1; i < count; i++) {
          handleAddSolutionAnswers(res.data.allAnswer);
          setActionButtonLoad(false);
          // }
          res.data.allAnswer.forEach((key) => {
            let arryForStoreWrong = [];
            key.wrong_answer.forEach((wrongAns) => {
              arryForStoreWrong.push(wrongAns.wrong_answer);
              // setWrongAnswer(res.data.message);
              // setLoad(false)
            });
            console.log(arryForStoreWrong);
            let arr = [];
            let errorCountArray = [];
            console.log(key._id);
            arr[`${key._id}`] = arryForStoreWrong;
            errorCountArray[`${key._id}`] = -1;
            console.log(arr, errorCountArray);
            //  arr.push( res.data.message)

            //  arr[key._id] = res.data.message;

            errorMessage.push(arr);
            console.log(errorMessage);
            errorMessageCount.push(errorCountArray);
            setarrErrorMessageCount(errorMessageCount);
            setarrErrorMessage(errorMessage);
            console.log("Wrong Answer from DB:", errorMessage);
            console.log("Error Count:", errorMessageCount);
            // axios
            //   .get(
            //     `${APIURI}/web-answer/wrong-answer/${key._id}`,
            //     config2 ? config2 : config
            //   )
            //   .then((res2) => {

            //   });
          });

          console.log("AnswerCount: ", ansCount);
          setTimeout(() => {
            UpdateMailToLinks();
          }, 10);
          setLoad(false);
          // axios
          //   .get(
          //     `${APIURI}/web-answer/all-answers/${sceneID}`,
          //     config2 ? config2 : config
          //   )
          //   .then((res) => {

          //   })
          //   .catch((err) => {
          //     setTimeout(() => {
          //       UpdateMailToLinks();
          //     }, 10);
          //     setLoad(false);
          //   });
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
          if (
            err.response &&
            err.response.data.message ===
              "Your team has not started the pod yet"
          ) {
            console.log("Asd");
            setPodNotStarted(true);
            if (err.response.data.podBody) {
              setPodId(err.response.data.podBody.podData[0][0]._id);
            }
            setActionButtonLoad(false);
            setLoad(false);
          } else {
            if (
              err.response &&
              err.response.data &&
              err.response.data.teamRequired
            ) {
              window.location.href = "/drawerpods";
            } else {
              setActionButtonLoad(false);
              setLoad(false);
              if (
                err.response.data.message === "Access code incorrect" ||
                err.response.data.message ===
                  "User email or access code is not correct"
              ) {
                setActionButtonLoad(false);
                setLoad(false);
                setIsAccessCodeRequired(true);
              } else if (accessToken === null) {
                if (gameId === undefined) {
                  window.location.href = "/auth";
                } else {
                  console.log(gameId, sceneID);
                  window.location.href = `/auth:${gameId}/${sceneID}`;
                }
              } else {
                console.log(err.response.status);
                if (err.response.status === 403) {
                  localStorage.removeItem("accesstoken");
                  if (gameId === undefined) {
                    window.location.href = "/auth";
                  } else {
                    console.log(gameId, sceneID);
                    window.location.href = `/auth:${gameId}/${sceneID}`;
                  }
                } else {
                  setNoScene(true);
                  setLoad(false);
                  setActionButtonLoad(false);
                }
              }
            }
          }
        });
    }
  };

  const handleHintModalOpen = () => {
    // setOpenHintModal(true);
    history.push({ hash: "#hintOpen" });
  };
  const handleHintModalClose = () => {
    // setOpenHintModal(false);
    history.goBack();
  };

  function getErrorMessageCount(answerID) {
    // if (!isInputActive) return;
    // console.log("answerID", answerID)
    // console.log("arrErrorMessage",arrErrorMessage[0])
    // console.log(arrErrorMessageCount)
    console.log(arrErrorMessageCount, arrErrorMessage, "countmessagebefore");
    for (let index = 0; index < arrErrorMessageCount.length; index++) {
      console.log(arrErrorMessageCount, "countmessage");
      if (arrErrorMessageCount[index][answerID] !== undefined) {
        // console.log(arrErrorMessageCount)
        // console.log(arrErrorMessageCount[index][answerID])
        let value = arrErrorMessageCount[index][answerID] + 1;
        // console.log("value---",value)
        // for(let ind=0; ind< arrErrorMessage[index][answerID].length; ind++){
        //   console.log(ind)
        //   console.log(arrErrorMessage[index][answerID][ind])
        //   return(arrErrorMessage[index][answerID][ind])
        // }
        // console.log(arrErrorMessage[index][answerID][arrErrorMessageCount[index][answerID]])
        // console.log(arrErrorMessage[index][answerID])
        if (
          value < arrErrorMessage[index][answerID].length &&
          arrErrorMessage[index][answerID].length % 2 !== 0
        ) {
          // console.log("Lenghth", arrErrorMessage[index][answerID].length);
          arrErrorMessageCount[index][answerID] = value;
          // console.log("value---:::::::::::::::::::",value)
          return arrErrorMessage[index][answerID][value];
        }
        if (
          value === arrErrorMessage[index][answerID].length &&
          arrErrorMessage[index][answerID].length % 2 !== 0
        ) {
          arrErrorMessageCount[index][answerID] = 0;
          // console.log(value)
          // console.log("Lenghth else", arrErrorMessage[index][answerID].length);
          return arrErrorMessage[index][answerID][0];
        }
        if (
          value < arrErrorMessage[index][answerID].length &&
          arrErrorMessage[index][answerID].length % 2 === 0
        ) {
          // console.log(arrErrorMessage[index][answerID].length)
          arrErrorMessageCount[index][answerID] = value;
          // console.log(arrErrorMessageCount[index][answerID])
          return arrErrorMessage[index][answerID][value];
        }
        if (
          value === arrErrorMessage[index][answerID].length &&
          arrErrorMessage[index][answerID].length % 2 === 0
        ) {
          // console.log(arrErrorMessage[index][answerID].length)
          arrErrorMessageCount[index][answerID] = 0;
          return arrErrorMessage[index][answerID][0];
        }
        console.log(arrErrorMessageCount);
        console.log(arrErrorMessageCount[index][answerID]);
        //   return arrErrorMessage[index][answerID][
        //   arrErrorMessageCount[index][answerID]
        // ];
      }
    }
    // for (let index = 0; index < arrErrorMessageCount.length; index++) {
    //   if (arrErrorMessageCount[index][answerID] !== undefined) {
    //     let value = arrErrorMessageCount[index][answerID] + 1;
    //     console.log("Value: ", value);
    //     console.log(
    //       arrErrorMessage[index][answerID][
    //         arrErrorMessageCount[index][answerID]
    //       ]
    //     );
    //     console.log("arrErrorMessage[index][answerID].length",arrErrorMessage[index][answerID].length)
    //     if (value < arrErrorMessage[index][answerID].length) {
    //       console.log("Lenghth", arrErrorMessage[index][answerID].length);
    //       arrErrorMessageCount[index][answerID] = value;
    //     }
    //     else {
    //       arrErrorMessageCount[index][answerID] = 0;
    //       console.log("Lenghth else", arrErrorMessage[index][answerID].length);
    //     }
    //     console.log(arrErrorMessageCount);
    //     console.log(arrErrorMessage[index][answerID][
    //       arrErrorMessageCount[index][answerID]
    //     ])
    //     return arrErrorMessage[index][answerID][
    //       arrErrorMessageCount[index][answerID]
    //     ];
    //   }
    // }
  }

  const handleWrongAnswerMessage = (id) => {
    return getErrorMessageCount(id);
  };
  localStorage.setItem("redirect_url", window.location.href);

  useEffect(() => {
    const handlePageShow = (event) => {
      // Check if the event's persisted property is false (back navigation)
      if (event.persisted) {
        // Reload the page after a brief delay
        setTimeout(() => {
          window.location.reload();
        }, 100); // Adjust the delay as needed
      }
    };

    window.addEventListener("pageshow", handlePageShow);

    return () => {
      window.removeEventListener("pageshow", handlePageShow);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    handelConfig();
    setTimeout(() => {
      LoadScene();
    }, 2000);
  }, [callApiAgain]);

  useEffect(() => {
    socketNotification.on("message", (data) => {
      console.log(data);
      setPushNotificationModal(true);
      setPushNotificationData(data.description);
    });
    return () => {
      socketNotification.disconnect();
    };
  }, [socketNotification]);

  // useEffect(() => {
  //   // Function to fetch data from the API
  //   const fetchData = async () => {
  //     Object.keys(localStorage).forEach((key) => {
  //       if (key.includes("PushNotification_")) {
  //         localStorage.removeItem(key);
  //       }
  //     });
  //     axios
  //       .get(`${APIURI}/web-pods/allMessages/${podId}`, config)
  //       .then((res2) => {
  //         if (
  //           res2.data.data.length &&
  //           res2.data.data[0].Pod_notifications.length > 0
  //         ) {
  //           console.log(res2.data.data[0].Pod_notifications, "all messages");
  //           let arry = [];
  //           res2.data.data[0].Pod_notifications.forEach((resData) => {
  //             if (resData.displayAt) {
  //               arry.push(resData);
  //               localStorage.setItem(
  //                 `PushNotification_${Math.floor(
  //                   Date.parse(resData.displayAt) / 1000
  //                 )}`,
  //                 resData.description
  //               );
  //             }
  //           });
  //           console.log(arry);

  //           // setPodNotificationsOnTime(arry);
  //         }
  //       });
  //   };

  //   // Set up the interval to call the API every 10 seconds
  //   const interval = setInterval(() => {
  //     if (podId) {
  //       fetchData();
  //     }
  //   }, 5000); // 10000 milliseconds = 10 seconds

  //   // Clean up the interval when the component unmounts
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [podId]);

  useEffect(() => {
    const ShowNotificationOnTime = () => {
      // const currentTimeStamp = Date.now();
      // var myDate = new Date(currentTimeStamp);
      // var pstDate = myDate.toLocaleString("en-US", {
      //   timeZone: "America/Los_Angeles",
      // });
      // console.log(Date.parse(pstDate), "pst date");
      // var now = new Date();
      // var utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
      // console.log(
      //   "utcasdf",
      //   Math.floor(Date.parse(new Date().toISOString()) / 1000)
      // );
      let utc_date = Math.floor(Date.parse(new Date().toISOString()) / 1000);

      // var pst_now = new Date(pstDate);
      // console.log("pst to utc ", Date.parse(new Date(pstDate).toISOString()));

      let notificationVar = localStorage.getItem(
        `PushNotification_${utc_date}`
      );
      if (notificationVar) {
        setPushNotificationModal(true);
        setPushNotificationData(notificationVar);
      }
    };

    // Call the function every second (1000 milliseconds)
    const intervalId = setInterval(ShowNotificationOnTime, 1000);

    // To stop calling the function, clear the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    UpdateMailToLinks();
    handelConfig();
  }, [load, callApiAgain]);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      Object.keys(localStorage).forEach((key) => {
        if (key.includes("PushNotification_")) {
          localStorage.removeItem(key);
        }
      });
      axios
        .get(`${APIURI}/web-scene/isSceneCompleteForTeam/${sceneID}`, config)
        .then((res) => {
          setIsSceneCompleteForTeam(res.data.teamSceneCompleted);
          if (
            res.data.messageList.length &&
            res.data.messageList[0].Pod_notifications.length > 0
          ) {
            console.log(
              res.data.messageList[0].Pod_notifications,
              "all messages"
            );
            let arry = [];
            res.data.messageList[0].Pod_notifications.forEach((resData) => {
              if (resData.displayAt) {
                arry.push(resData);
                localStorage.setItem(
                  `PushNotification_${Math.floor(
                    Date.parse(resData.displayAt) / 1000
                  )}`,
                  resData.description
                );
              }
            });
            console.log(arry);
          }
        });
    };

    // Set up the interval to call the API every 10 seconds
    const interval = setInterval(() => {
      console.log(isPodActive);
      if (isPodActive && !isSceneCompleteForTeam) {
        fetchData();
      }
    }, 6000); // 10000 milliseconds = 10 seconds

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(interval);
    };
  }, [isPodActive]);

  // useEffect(() => {
  //   axios
  //     .get(`${APIURI}/web-game/fetchAccessStatusUser/${gameId}`, config)
  //     .then((res) => {
  //       console.log(res, "148");
  //       if (res.data.message.status !== "None") {
  //         setIsAccessCodeRequired(true);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err.message);
  //     });
  // }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClosePopover = () => {
    setTooglePopover(false);
    // window.location.reload();
    // handleTooltip();
  };

  const NoQRComponent = () => {
    return (
      <Dialog
        open={noQR}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Did you skip the Scene QR Code?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Oops! looks like you’ve skipped the previous QR Code, Please scan
            the Scene QR Code to continue.!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="dark"
            autoFocus
            variant="contained"
          >
            Go Back
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const NoOrderComponent = () => {
    return (
      <Dialog
        open={IsOutofOrder}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            className="dialog-desc"
          >
            Looks like you skipped a puzzle! Go back to your last step and make
            sure you opened the correct envelope.
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ padding: "2em" }}>
          <Button
            className="dialog-btn-dismiss"
            autoFocus
            variant="contained"
            // href="www.google.com"
            onClick={(e) => setIsOutofOrder(false)}
            // onClick={(e) => {window.location.href=`/location:${gameId}`}}
          >
            {/* CONTINUE */}
            DISMISS
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const GameIsNotScaned = () => {
    if (isAccessCodeRequired) {
      return (
        <Dialog
          open={isAccessCodeRequired}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="access--code--modal"
        >
          <DialogTitle
            id="alert-dialog-title"
            className="access--code--required"
          >
            {"Access Code Required"}
          </DialogTitle>
          <DialogContent>
            <div className="Location-Footer-Content">
              <div className="loc-enter">
                <label className="access--code--required--label">
                  Enter Access Code
                </label>
                <input
                  type="text"
                  defaultValue={accessCode}
                  placeholder="TYPE HERE"
                  onChange={(e) => {
                    setAccessCode(e.target.value);
                    setIncorrectCodeError("");
                  }}
                  style={{ marginBottom: "10px" }}
                />
              </div>
              <p style={{ color: "#dc3545", margin: "0", fontWeight: "700" }}>
                {incorrectCodeError ? incorrectCodeError : ""}
              </p>
            </div>
          </DialogContent>
          <DialogActions style={{ padding: "16px 24px" }}>
            <Button
              className="dialog-btn-dismiss"
              autoFocus
              variant="contained"
              style={{ width: "100%" }}
              onClick={() => handleValidateCode()}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      );
    } else
      return (
        <Dialog
          open={!IsGameComplete && isFirstSceneRequired}
          onClose={() => {
            setIsGameComplete(true);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              className="dialog-desc"
            >
              {/* Looks like you skipped the QR code on the outside of the box!
              Click continue to see what you missed. */}
              Looks like you skipped the QR code on the outside of the box!
              Click continue to see what you missed, then try again.
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ padding: "2em" }}>
            <Button
              className="dialog-btn-dismiss"
              autoFocus
              variant="contained"
              // href="www.google.com"
              // onClick={(e) => setIsOutofOrder(false)}
              onClick={(e) => {
                window.location.href = `/location:${gameId}`;
              }}
            >
              CONTINUE
            </Button>
          </DialogActions>
        </Dialog>
      );
  };

  const NoSceneComponent = () => {
    return (
      <Dialog
        open={noScene}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"INVALID LINK"}</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            className="dialog-desc"
          >
            If you believe you have received this message in error, please
            contact{" "}
            <a href="mailto:support@escapely.com">support@escapely.com</a>.
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ padding: "2em" }}>
          <Button className="dialog-btn-dismiss" autoFocus variant="contained">
            DISMISS
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  if (load || actionButtonLoad) {
    return <Loader />;
  }

  // if (noQR) {
  //   return <NoQRComponent />;
  // }
  // else if(!IsGameComplete){
  //   return <GameIsNotScaned />
  // }
  if (!IsGameComplete && isFirstSceneRequired) {
    return GameIsNotScaned();
  }
  // else if (IsOutofOrder) {
  //   return <NoOrderComponent />;
  // }
  else if (noScene) {
    return <NoSceneComponent />;
  } else {
    return (
      <React.Fragment>
        <div className="Solution" style={{ paddingBottom: "100px" }}>
          {/* {console.log("solution component")} */}
          <Dialog
            open={togglePopover}
            onClose={handleClosePopover}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="PopOver"
          >
            {/* <DialogTitle id="alert-dialog-title">Message</DialogTitle> */}
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <div
                  dangerouslySetInnerHTML={createMarkup(togglePopoverMessage)}
                />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClosePopover}
                style={{ marginBottom: "2.2rem" }}
                className="dialog-btn-dismiss"
                autoFocus
                variant="contained"
              >
                Okay
              </Button>
              {/* <Camera onTakePhoto = { (dataUri) => { handleTakePhoto(dataUri); } }/> */}
            </DialogActions>
          </Dialog>
          <div>
            {sceneDetails.map((data, index) => {
              return (
                <div key={index}>
                  <div>
                    {data.image ? (
                      <img
                        src={data.image}
                        alt={data.title}
                        style={{
                          width: "100.5%",
                          marginLeft: "-1px",
                          marginTop: "-1px",
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="header">
                    <h2 className="solutionTitle">{data.title}</h2>
                  </div>
                  <hr />
                  <div className="container solution-container">
                    <div className="media-container">
                      {SceneDescription.map((data) => {
                        if (data.type !== "success_description") {
                          return (
                            <div key={data._id}>
                              {/* {data.key==="Audio"?"":<ContentTypeComponent content={data} />} */}
                              {/* <ContentTypeComponent content={data} /> */}
                              {ContentTypeComponent(data)}
                            </div>
                          );
                        }
                      })}
                    </div>
                  </div>
                  {/* <hr /> */}
                </div>
              );
            })}
          </div>
          {/* {sceneDetails !== null ? (
            <div>
              {sceneDetails.map((data, index) => {
                return (
                  <div key={index}>
                    <div className="header">
                      <h2 className="solutionTitle">{data.title}</h2>
                    </div>
                    <hr />
                    <div className="container">
                      <div className="media-container">
                        {SceneDescription.map((data) => {
                          if (data.type !== "success_description") {
                            return (
                              <div key={data._id}>
                                {/* {data.key==="Audio" && playing?"":<ContentTypeComponent content={data} />} 
                                <ContentTypeComponent content={data} />
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                    <hr />
                  </div>
                );
              })}
            </div>
          ) : (
            <p />
          )} */}
          {/* <div className="media-container">
                        {SceneDescription.map((data) => {
                          if (data.type !== "success_description") {
                            return (
                              <div key={data._id} className="solution-media">
                                
                                  {data.key==="Audio"?<>{console.log("new audio component")} <Audio content={data.value} id={data._id}/></>:""}
                              </div>
                              
                              );
                            }
                          }
                          )}
                          <hr /> 
          </div> */}
          <div className="container-sol">
            {sceneDetails.map((data) => (
              <div
                key={data._id}
                className="sol-screen"
                dangerouslySetInnerHTML={createMarkup(solMSG)}
              />
            ))}

            {actionButtonLoad ? (
              ""
            ) : (
              <>
                {isSceneCompleteForTeam ? (
                  <>
                    {totalAnswers.length > 0 ? (
                      <div className="container-code">
                        <Button
                          className="submit--btn"
                          variant="contained"
                          onClick={(e) => {
                            handleAnswerValidateContinue(e);
                          }}
                        >
                          SOLVED
                        </Button>
                        <p className="save--photo--p">Click to continue.</p>
                      </div>
                    ) : (
                      <div className="container-code">
                        <Button
                          className="submit--btn"
                          variant="contained"
                          onClick={() => {
                            handleAnswerValidateContinue();
                          }}
                        >
                          CONTINUE
                        </Button>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {emailCollectionField ? (
                      <div className="container-code">
                        <div className="sol-enter">
                          <label>Email</label>
                          <input
                            type="text"
                            Value={email}
                            placeholder="Enter Email"
                            onChange={(e) => setEmail(e.target.value)}
                            // onFocus={(e) => handleFocus(index, e, key._id)}
                          />
                          <p />
                        </div>
                        {buttonLoad ? (
                          <Button
                            className="submit--btn disabled"
                            variant="contained"
                            disabled={true}
                          >
                            SUBMIT
                          </Button>
                        ) : (
                          <Button
                            className="submit--btn"
                            variant="contained"
                            onClick={(e) => {
                              handleEmailCollection(e);
                            }}
                          >
                            SUBMIT
                          </Button>
                        )}
                      </div>
                    ) : (
                      <div className="container-code">
                        {/* <TextField  fullWidth id="outlined-basic" label="Submit Answer" variant="outlined" placeholder="TYPE HERE" onChange={(e) => setAnswer(e.target.value)}/> <br /> */}
                        {totalAnswers.map((key, index) => {
                          // console.log("Feom JSX:", index);
                          return (
                            <div className="sol-enter" key={key._id}>
                              <label>{key.label}</label>
                              <input
                                type="text"
                                value={
                                  solutionAnswer[index] &&
                                  solutionAnswer[index].answer
                                }
                                placeholder={key.placeholder || "TYPE HERE"}
                                onChange={(e) =>
                                  handleSolutionInputs(index, e, key._id)
                                }
                                onFocus={(e) => handleFocus(index, e, key._id)}
                              />
                              {wrongAnswers.includes(key._id) ? (
                                <>
                                  {/* {console.log("key._id",key._id)} */}
                                  {buttonLoad ? (
                                    <p />
                                  ) : solutionAnswer[index].answer === "" ? (
                                    <p />
                                  ) : (
                                    <>
                                      <img
                                        className="wrng-icon"
                                        src="/assets/Wrong.svg"
                                        alt="WrongAnswer"
                                        onClick={(e) => {
                                          handleRedCrossClick(
                                            index,
                                            e,
                                            key._id
                                          );
                                        }}
                                      />
                                      {isOnChangeRun ? (
                                        <p />
                                      ) : (
                                        <p className="wrong-ans">
                                          {handleWrongAnswerMessage(key._id)}
                                        </p>
                                      )}
                                    </>
                                  )}
                                </>
                              ) : (
                                <p />
                              )}
                            </div>
                          );
                        })}
                        {totalAnswers.length > 0 ? (
                          <Button
                            className="submit--btn"
                            variant="contained"
                            style={{ opacity: `${buttonLoad ? "0.6" : "1"}` }}
                            onClick={(e) => {
                              handleAnswerValidate(e);
                            }}
                            disabled={buttonLoad}
                          >
                            SUBMIT
                          </Button>
                        ) : hideContinueBtn ? (
                          ""
                        ) : (
                          <Button
                            className="submit--btn"
                            variant="contained"
                            onClick={() => {
                              handleAnswerValidateContinue();
                            }}
                          >
                            CONTINUE
                          </Button>
                        )}
                      </div>
                    )}
                  </>
                )}
              </>
            )}

            {/* {(isAnswerWrong)? <div className="wrong-ans">
                          Wrong Answer - {wronganswer.map(v => <> {v} </>)}
                      </div>: <></> } */}
          </div>
          {/* <hr /> */}

          <div className="container-sol">
            <div className="cta" style={{ marginTop: "5.5em" }}>
              {/* <a className="ml-20" href={`/hints:${sceneID}`}>
                <img
                  className="solution-icon"
                  src="/assets/Help@3x.png"
                  alt=""
                />{" "}
                Hint
              </a>
              <a className="mr-20" href={`/tutorial:${gameId}`}>
                <img
                  className="solution-icon"
                  src="/assets/Settings@3x.png"
                  alt=""
                />{" "}
                Tutorial
              </a> */}
            </div>
          </div>
        </div>
        {/* {isLoginRequired ? <LogoutComponent /> : ""} */}

        <Footer
          tutorialLink={`/tutorial:${gameId}`}
          hintLink={handleHintModalOpen}
          isLoginRequired={isLoginRequired}
          hideContinueBtn={hideContinueBtn}
          totalAnswersLenght={totalAnswers.length}
          hasHints={hasHints}
        />

        <Dialog
          className="hints__Popup"
          // open={openHintModal}
          open={window.location.href.includes("#hintOpen")}
          onClose={() => {
            // setOpenHintModal(false);
            history.goBack();
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {/* <DialogTitle id="alert-dialog-title">Message</DialogTitle> */}
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {/* <div dangerouslySetInnerHTML={createMarkup(pushNotificationData)} /> */}
              {/* <div>
                <p>{pushNotificationData}</p>
              </div> */}
              <Hints handleCloseHint={handleHintModalClose} />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {/* <Button
              onClick={() => {
                setPushNotificationModal(false);
              }}
              style={{ marginBottom: "2.2rem" }}
              className="dialog-btn-dismiss"
              autoFocus
              variant="contained"
            >
              Okay
            </Button> */}
            {/* <Camera onTakePhoto = { (dataUri) => { handleTakePhoto(dataUri); } }/> */}
          </DialogActions>
        </Dialog>

        <Dialog
          open={isAccessCodeRequired}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="access--code--modal"
        >
          <DialogTitle
            id="alert-dialog-title"
            className="access--code--required"
          >
            {"Access Code Required"}
          </DialogTitle>
          <DialogContent>
            <div className="Location-Footer-Content">
              <div className="loc-enter">
                <label className="access--code--required--label">
                  Enter Access Code
                </label>
                <input
                  type="text"
                  defaultValue={accessCode}
                  placeholder="TYPE HERE"
                  onChange={(e) => {
                    setAccessCode(e.target.value);
                    setIncorrectCodeError("");
                  }}
                  style={{ marginBottom: "10px" }}
                />
              </div>
              <p style={{ color: "#dc3545", margin: "0", fontWeight: "700" }}>
                {incorrectCodeError ? incorrectCodeError : ""}
              </p>
            </div>
          </DialogContent>
          <DialogActions style={{ padding: "16px 24px" }}>
            <Button
              className="dialog-btn-dismiss"
              autoFocus
              variant="contained"
              style={{ width: "100%" }}
              onClick={() => handleValidateCode()}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>

        {/* push nofication  */}
        <Dialog
          open={pushNotificationModal}
          onClose={() => {
            setPushNotificationModal(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="PopOver"
        >
          {/* <DialogTitle id="alert-dialog-title">Message</DialogTitle> */}
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {/* <div dangerouslySetInnerHTML={createMarkup(pushNotificationData)} /> */}
              <div>
                <p>{pushNotificationData}</p>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setPushNotificationModal(false);
              }}
              style={{ marginBottom: "2.2rem" }}
              className="dialog-btn-dismiss"
              autoFocus
              variant="contained"
            >
              Okay
            </Button>
            {/* <Camera onTakePhoto = { (dataUri) => { handleTakePhoto(dataUri); } }/> */}
          </DialogActions>
        </Dialog>

        {/* not started pod  */}
        <Dialog
          open={podNotStarted}
          // onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
            {"START YOUR TIME"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              className="dialog-desc"
            >
              Your team has not clicked start. Return to your team homepage
              below, click start, then rescan this code to play.
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ padding: "2em" }}>
            <Button
              className="dialog-btn-dismiss"
              autoFocus
              variant="contained"
              onClick={() => {
                window.location.href = `/pods:${podId}`;
              }}
            >
              TEAM HOMEPAGE
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}
export default withRouter(Solutions);

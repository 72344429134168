import React from "react";
import { useLocation } from "react-router-dom";

const Footer = ({
  tutorialLink,
  hintLink,
  isLoginRequired,
  hideContinueBtn,
  totalAnswersLenght,
  hasHints,
}) => {
  console.log(
    hasHints,
    !hideContinueBtn && totalAnswersLenght > 0 && hasHints,
    hideContinueBtn,
    totalAnswersLenght
  );
  const location = useLocation();
  const isProile = location.pathname.includes("profile");
  const handleLogout = (e) => {
    console.log(location.pathname);
    e.preventDefault();
    localStorage.removeItem("accesstoken");
    localStorage.clear();
    if (
      location.pathname === "/drawer" ||
      location.pathname.includes("/congrats")
    ) {
      window.location.href = "/auth";
    } else {
      window.location.reload();
    }
  };

  if (isLoginRequired) {
    return (
      <div className="fixed__div">
        <div className="footer__container">
          <div
            onClick={() => {
              localStorage.setItem("backLink", window.location.href);
              setTimeout(() => {
                window.location.href = "/profile";
              }, 500);
            }}
          >
            <img
              src={
                isProile
                  ? "/assets/ProfilePicIcon.svg"
                  : "/assets/AccountIcon_white.svg"
              }
              alt=""
              srcSet=""
            />
            {/* <img src="/assets/ProfilePicIcon.svg" alt="" srcSet="" /> */}
            <span>ACCOUNT</span>
          </div>
          <div
            onClick={() =>
              window.open("https://escapely.com/shop/", "_blank")
            }
          >
            <img src="/assets/ShopIcon_white.svg" alt="" srcSet="" />
            {/* <img src="/assets/ShopIcon_Yellow-01.svg" alt="" srcSet="" /> */}
            <span>SHOP</span>
          </div>
          <div
            onClick={() => {
              // if (!hideContinueBtn && totalAnswersLenght > 0 && hasHints) {
              if (hasHints) {
                hintLink();
              }
            }}
          >
            <img
              src={
                // !hideContinueBtn && totalAnswersLenght > 0 && hasHints
                hasHints
                  ? "/assets/HintsIcon_white.svg"
                  : "/assets/HintsIcon_Gray.svg"
              }
              alt=""
              srcSet=""
              className="hint__img__footer"
            />
            <span>HINTS</span>
          </div>
          <div
            onClick={() => {
              window.location.href = tutorialLink;
            }}
          >
            <img src="/assets/TutorialIcon_white.svg" alt="" srcSet="" />
            {/* <img src="/assets/TutorialIcon_Yellow-01.svg" alt="" srcSet="" /> */}
            <span>TUTORIAL</span>
          </div>
          <div onClick={(e) => handleLogout(e)}>
            <img src="/assets/LogoutIcon_white.svg" alt="" srcSet="" />
            {/* <img src="/assets/LogoutIcon_Yellow-01.svg" alt="" srcSet="" /> */}
            <span>LOGOUT</span>
          </div>
        </div>
      </div>
    );
  }

  //if login is not required
  else
    return (
      <div className="fixed__div">
        <div className="footer__container__loginNotRequired">
          <div
            onClick={() => {
              window.open("https://escapely.com/shop/", "_blank");
            }}
          >
            <img src="/assets/ShopIcon_white.svg" alt="" srcSet="" />
            {/* <img src="/assets/ShopIcon_Yellow-01.svg" alt="" srcSet="" /> */}
            <span>SHOP</span>
          </div>
          <div
            onClick={() => {
              // if (!hideContinueBtn && totalAnswersLenght > 0 && hasHints) {
              if (hasHints) {
                hintLink();
              }
            }}
          >
            <img
              src={
                // !hideContinueBtn && totalAnswersLenght > 0 && hasHints
                hasHints
                  ? "/assets/HintsIcon_white.svg"
                  : "/assets/HintsIcon_Gray.svg"
              }
              alt=""
              srcSet=""
              className="hint__img__footer"
            />
            <span>HINT</span>
          </div>
          <div
            onClick={() => {
              window.location.href = tutorialLink;
            }}
          >
            <img src="/assets/TutorialIcon_white.svg" alt="" srcSet="" />
            {/* <img src="/assets/TutorialIcon_Yellow-01.svg" alt="" srcSet="" /> */}
            <span>TUTORIAL</span>
          </div>
        </div>
      </div>
    );
};

export default Footer;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

export default function LogoutComponent() {
  const handleLogout = (e) => {
    e.preventDefault();
    localStorage.removeItem("accesstoken");
    window.location.reload();
  };
  return (
    <div className="logout">
      Need to switch accounts?{" "}
      <strong>
        <a onClick={(e) => handleLogout(e)}>Logout here</a>
      </strong>
    </div>
  );
}

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import CountdownTimer from "../components/CountdownTimer";
import axios from "axios";
import { APIURI } from "../config";
import { useHistory, withRouter } from "react-router";
import createMarkup from "../utils/CreateMarkup";

const PodStartStory = ({
  podDetails,
  teamInfo,
  isSomeThingUpdated,
  podStatus,
  isTeamCreator,
  hasPodStarted,
  teamPageDesc,
}) => {
  const [isPodStarted, setIsPodStarted] = useState(false);
  const [btnLoad, setBtnLoad] = useState(false);
  const [isTimerEnd, setIsTimerEnd] = useState(false);
  const [hasPodStartedOnPage, sethasPodStartedOnPage] = useState(hasPodStarted);
  const [teamMembers, setTeamMembers] = useState(teamInfo.UserNames);
  const [isScrollable, setIsScrollable] = useState(false);
  const [leaveTeamModal, setLeaveTeamModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [togglePopoverArr, setTooglePopoverArr] = useState([]);
  const [togglePopover, setTooglePopover] = useState(false);
  const [togglePopoverMessage, setTooglePopoverMessage] = useState([]);
  const [countForShowModal, setCountForShowModal] = useState(0);
  console.log(isTeamCreator);
  let history = useHistory();

  async function handleTooltip() {
    let elements = document.getElementsByClassName("tooltip");
    let arr = await togglePopoverArr;
    let myFunction = function (e, i, acID) {
      e.preventDefault();
      console.log(arr);
      console.log(e, i, arr[0][acID]);
      setTooglePopover(true);
      setTooglePopoverMessage(arr[0][acID]);
    };

    for (let i = 0; i < elements.length; i++) {
      let actionBtn = elements[i];
      let actionBtnId = actionBtn.id.split("-");
      // console.log(actionBtnId[1]);
      actionBtn.addEventListener(
        "mousedown",
        (e) => myFunction(e, i, actionBtnId[1]),
        false
      );
    }
  }

  const handleClosePopover = () => {
    setTooglePopover(false);
    // window.location.reload();
    // handleTooltip();
  };

  const handleIsTimerEnd = () => {
    if (!isTimerEnd) {
      setIsTimerEnd(true);
    }
  };

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      axios
        .get(`${APIURI}/web-teams/getAllTeam/${teamInfo.data._id}`, config)
        .then((res) => {
          console.log(res);
          setTeamMembers(res.data.UserNames);
          sethasPodStartedOnPage(res.data.data.hasStarted);
          if (res.data.data.hasStarted) {
            if (countForShowModal === 0) {
              setIsPodStarted(true);
            }
          }
        });
    };

    // Set up the interval to call the API every 10 seconds
    const interval = setInterval(() => {
      fetchData();
    }, 7000); // 10000 milliseconds = 10 seconds

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(interval);
    };
  }, [countForShowModal]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const accessToken = localStorage.getItem("accesstoken");
  let config;
  config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };

  const handleStartPod = () => {
    setBtnLoad(true);
    axios
      .get(`${APIURI}/web-pods/startPod/${teamInfo.data._id}`, config)
      .then((res) => {
        setBtnLoad(false);
        console.log(res);
        setIsPodStarted(true);
        sethasPodStartedOnPage(true);
      })
      .catch((err) => {
        setBtnLoad(false);
        console.log(err);
      });
  };

  const handleLeaveTeam = () => {
    axios
      .get(`${APIURI}/web-teams/leaveTeam/${teamInfo.data._id}`, config)
      .then((res) => {
        console.log(res);
        isSomeThingUpdated();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePopUps = (data) => {
    let desc = data;
    if (podDetails && podDetails.Pod_popup) {
      let arrPop = togglePopoverArr;
      arrPop.push(podDetails.Pod_popup.map((te) => te.description));
      console.log(arrPop);
      setTooglePopoverArr(arrPop);
    }
    if (podDetails && podDetails.Pod_popup) {
      podDetails.Pod_popup.map((te, i) => {
        if (te.icon) {
          desc = desc.replaceAll(
            te.identifier,
            `<span class="tooltip" id='tooltip-${i}'><img src="${te.icon}" alt="PopupIcon" /><span>${te.title}</span></span>`
          );
        } else {
          desc = desc.replaceAll(
            te.identifier,
            `<span class="tooltip" id='tooltip-${i}'><img src ="/assets/PopupIcon.svg" alt="PopupIcon" /><span>${te.title}</span></span>`
          );
        }
        handleTooltip();
      });
    }
    return (
      <div
        // className="sol-screen"
        className="desc-Text-solution"
        dangerouslySetInnerHTML={createMarkup(desc)}
      />
    );
  };
  const popupMemo = useMemo(() => handlePopUps(teamPageDesc), []);

  return (
    <>
      <div className="Solution pod-start-screen">
        <div className="header">
          <h2 className="solutionTitle">{podDetails && podDetails.title}</h2>
        </div>
        <hr />
        <div className="container-sol">
          <div className="container-code">
            <h3 className="code--to--join">
              CODE TO JOIN:{teamInfo.data.teamCode}
            </h3>
            <div
            //  style={{ height: "45vh", overflowY: "scroll" }}
            >
              <h3 style={{ textAlign: "center" }}>
                {teamInfo.data.teamName.toUpperCase()}
              </h3>
              {/* {teamInfo.UserEmails.map((res) => {
            return <p>{res}</p>;
          })} */}
              <div className="team--members--div">
                {teamMembers.map((res) => {
                  return <p style={{ textAlign: "center" }}>{res}</p>;
                })}
              </div>

              {popupMemo}
            </div>
          </div>
        </div>
        <div className="start--section--fixed">
          <h3
            style={{
              marginTop: "0",
              textAlign: "center",
              backgroundColor: "#fffb00",
              color: "#000",
              padding: "0.5em",
            }}
          >
            YOUR ADVENTURE <br /> BEGINS IN: <br />
            <span style={{ fontWeight: "bolder" }}>
              {" "}
              <CountdownTimer
                targetDate={podDetails.fromDate}
                handleIsTimerEnd={handleIsTimerEnd}
              />
            </span>
          </h3>
          <div className="container-sol">
            <div className="container-code">
              {hasPodStartedOnPage ? (
                <Button
                  className="submit--btn margin--0"
                  variant="contained"
                  disabled
                  style={{
                    opacity: `0.6`,
                  }}
                >
                  STARTED
                </Button>
              ) : (
                <Button
                  className="submit--btn margin--0"
                  variant="contained"
                  disabled={(podStatus !== "active" && !isTimerEnd) || btnLoad}
                  style={{
                    opacity: `${
                      (podStatus === "active" || isTimerEnd) && !btnLoad
                        ? "1"
                        : "0.6"
                    }`,
                  }}
                  onClick={() => {
                    handleStartPod();
                  }}
                >
                  START
                </Button>
              )}

              <Button
                className="dialog-btn-dismiss--transparent dialog-btn-dismiss margin--top--1rem"
                // className="dialog-btn-dismiss margin--top--1rem"
                autoFocus
                variant="contained"
                style={{ width: "100%" }}
                onClick={() => {
                  if (isTeamCreator) {
                    setLeaveTeamModal(true);
                  } else {
                    handleLeaveTeam();
                  }
                }}
              >
                LEAVE TEAM
              </Button>
            </div>
          </div>
        </div>
      </div>

      {/* PodStarted modal  */}
      <Dialog
        open={isPodStarted}
        onClose={() => {
          setIsPodStarted(false);
          setCountForShowModal(countForShowModal + 1);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="PopOver"
      >
        {/* <DialogTitle id="alert-dialog-title">Message</DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {/* <div dangerouslySetInnerHTML={createMarkup(pushNotificationData)} /> */}
            <div>
              <p>Your time has started! Scan any game QR code to begin play.</p>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsPodStarted(false);
              setCountForShowModal(countForShowModal + 1);
            }}
            style={{ marginBottom: "2.2rem" }}
            className="dialog-btn-dismiss"
            autoFocus
            variant="contained"
          >
            Okay
          </Button>
        </DialogActions>
      </Dialog>

      {/* leave team modal for creator  */}
      <Dialog
        open={leaveTeamModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="access--code--modal create--join--team--modals"
      >
        <DialogTitle id="alert-dialog-title" className="access--code--required">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>{"Leave Team ?"}</span>
            <Button
              className="image--capture--modal--close--btn"
              autoFocus
              variant="contained"
              onClick={() => {
                setErrorMessage("");
                setLeaveTeamModal(false);
              }}
            ></Button>
          </div>
        </DialogTitle>
        <DialogContent>Are you sure want to leave this team ?</DialogContent>
        <DialogActions
          style={{
            padding: "16px 24px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Button
            className="dialog-btn-dismiss"
            autoFocus
            variant="contained"
            style={{
              width: "100%",
              color: "#fffb00",
              // opacity: `${codeForJoin.length < 4 || btnLoad ? "0.7" : "1"}`,
            }}
            // disabled={codeForJoin.length < 4 || btnLoad}
            onClick={() => {
              handleLeaveTeam();
            }}
          >
            LEAVE TEAM
          </Button>
          <Button
            className="dialog-btn-dismiss leave--team--modal--btn"
            autoFocus
            variant="contained"
            style={{
              width: "100%",
              color: "#fffb00",
              // opacity: `${codeForJoin.length < 4 || btnLoad ? "0.7" : "1"}`,
            }}
            // disabled={codeForJoin.length < 4 || btnLoad}
            onClick={() => {
              setLeaveTeamModal(false);
            }}
          >
            STAY IN TEAM
          </Button>
        </DialogActions>
      </Dialog>

      {/* popup modal  */}
      <Dialog
        open={togglePopover}
        onClose={handleClosePopover}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="PopOver"
      >
        {/* <DialogTitle id="alert-dialog-title">Message</DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div dangerouslySetInnerHTML={createMarkup(togglePopoverMessage)} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClosePopover}
            style={{ marginBottom: "2.2rem" }}
            className="dialog-btn-dismiss"
            autoFocus
            variant="contained"
          >
            Okay
          </Button>
          {/* <Camera onTakePhoto = { (dataUri) => { handleTakePhoto(dataUri); } }/> */}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PodStartStory;

import {
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import axios from "axios";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router";
import { withRouter } from "react-router-dom";
import Swal from "sweetalert2";
import { APIURI } from "../config";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { TermsPopUp } from "../popups/TermsPopUp";
import TermsChecked from "../context/TermsChecked";
import IsTermsPopUp from "../context/IsTermsPopUp";

function ForgotPassword(props) {
  let history = useHistory();
  const gameId = props.match.params.gameID;
  const sceneId = props.match.params.sceneId;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");
  const [fullname, setFullname] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isTermsChecked, setIsTermsChecked] = useContext(TermsChecked);
  const [isPopupOpen, setIsPopupOpen] = useContext(IsTermsPopUp);
  let currentUrl = window.location.href.split(":").pop();
  console.log(currentUrl);
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  const handleSave = (event) => {
    event.preventDefault();
    setLoading(true);
    const data = {
      email: email.toLowerCase(),
    };
    // if (isTermsChecked) {
    if (email) {
      axios
        .patch(`${APIURI}/users/forgot`, data)
        .then(
          (res) => {
            console.log(res);
            if (
              res.data.message.includes("Customer not found with this email")
            ) {
              setErrorMessage(
                "Looks like we don’t have your email in our system! Click below to create an account."
              );
              setLoading(false);
            } else {
              setErrorMessage(res.data.message + ".");
              setLoading(false);
            }
            setLoading(false);
          }
          //   {
          //     if (res.data.error) {
          //       if (res.data.message === '"confirmPassword" must be [ref:password]') {
          //         setErrorMessage("Passwords do NOT match!");
          //         setLoading(false);
          //       } else if (
          //         res.data.message ===
          //         '"password" length must be at least 4 characters long'
          //       ) {
          //         setErrorMessage(
          //           "Password length must be at least four characters long."
          //         );
          //         setLoading(false);
          //       } else {
          //         setErrorMessage(res.data.message);
          //         setLoading(false);
          //       }
          //     } else {
          //       const data = {
          //         email: email.toLowerCase(),
          //         password: password,
          //       };
          //       axios
          //         .post(`${APIURI}/users/login`, data)
          //         .then((loginRes) => {
          //           const token = loginRes.data.accessToken;
          //           console.log(token);
          //           localStorage.setItem("accesstoken", token);
          //           const prevURL = localStorage.getItem("redirect_url");
          //           if (gameId === undefined) {
          //             if (prevURL) {
          //               window.location.href = prevURL;
          //             } else {
          //               setErrorMessage(
          //                 "Scan QR code before logging in to begin play."
          //               );
          //             }
          //             setLoading(false);
          //           } else {
          //             if (prevURL) {
          //               window.location.href = prevURL;
          //             } else if (currentUrl.split("/").pop() === "0") {
          //               // setErrorMessage("Scan QR code before logging in to begin play.");
          //               window.location.href = `/location:${
          //                 currentUrl.split("/")[0]
          //               }`;
          //             } else if (currentUrl) {
          //               // setErrorMessage("Scan QR code before logging in to begin play.");
          //               window.location.href = `/solution:${currentUrl}`;
          //             } else {
          //               setErrorMessage(
          //                 "Scan QR code before logging in to begin play."
          //               );
          //             }
          //             setLoading(false);
          //           }
          //         })
          //         .catch(function (loginerror) {
          //           if (loginerror.response) {
          //             setErrorMessage(loginerror.response.data.message);
          //             setLoading(false);
          //           }
          //         });
          //     }
          //     console.log(res.data);
          //   }
        )
        .catch(function (error) {
          console.log(error);
          setLoading(false);
        });
    } else {
      setErrorMessage("Please enter a valid email.");
      setLoading(false)
    }
  };
  // else {
  //   setErrorMessage("Please accept Terms & Conditions.");
  //   setLoading(false);
  // }
  //   };
  return (
    <div className="Auth-login">
      <div className="Auth-header">
        <button className="backbutton-signup" onClick={(e) => history.goBack()}>
          <ArrowBackIcon />
        </button>
        <div className="container">
          <img
            src="/assets/Escapely@3x.png"
            alt="Logo"
            style={{ width: "100%" }}
          />
        </div>

        <p>Enter email to reset password</p>
      </div>
      <div className="Auth-container">
        <input
          className="login-input login-email"
          placeholder="ENTER EMAIL"
          autocomplete="off"
          onChange={(e) => {
            setEmail(e.target.value);
            setErrorMessage("");
          }}
        />
        <label className="mb-15">EMAIL</label>
        <br />
        <p className="wrong-ans">{errorMessage}</p>
        {/* {isPopupOpen ? <TermsPopUp type="signup" /> : ""}
        <div style={{ display: "flex", alignItems: "center" }}>
          <FormControlLabel
            style={{ marginRight: "5px" }}
            className="terms-condition-label"
            control={
              <Checkbox
                className="terms-checkbox"
                onClick={() => {
                  setIsPopupOpen(true);
                }}
                name="checkedB"
                checked={isTermsChecked}
                color="dark"
              />
            }
            label=<p className="terms-condition">I accept the</p>
          />
          <span
            className="terms-condition"
            onClick={() => {
              setIsPopupOpen(true);
            }}
            style={{ color: "#000", textDecoration: "underline" }}
          >
            Terms & Conditions
          </span>
        </div> */}
        {/* <FormControlLabel control={ <Checkbox checked={true} name="checkedB" color="primary" />} label="I agree to the terms and conditions"/> <br /> */}
        {loading ? (
          <Button
            className="signup"
            style={{ background: "#333200" }}
            variant="contained"
            disabled={true}
          >
            Confirm
          </Button>
        ) : (
          <Button className="signup" variant="contained" onClick={handleSave}>
            Confirm
          </Button>
        )}
      </div>
      <div className="forgot-pass">
        <div style={{ width: "48%", borderRight: "1.8px solid #000" }}>
          {gameId === undefined ? (
            <a href="/signup">Create Account</a>
          ) : (
            <a href={`/signup:${gameId}/${sceneId}`}>Create Account</a>
          )}
        </div>
        <div style={{ width: "51%" }}>
          {gameId === undefined ? (
            <a href="/auth">Sign in</a>
          ) : (
            <a href={`/auth:${gameId}/${sceneId}`}>Sign in</a>
          )}
        </div>
      </div>
      <br />
      <br /> <br />
      <br />
      <br /> <br /> <br />
    </div>
  );
}
export default withRouter(ForgotPassword);

import React, { useEffect, useState } from "react";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import EditIcon from "@material-ui/icons/Edit";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { useHistory } from "react-router";
import axios from "axios";
import { APIURI } from "../config";
import Loader from "../utils/Loader";
import ChangePasswordScreen from "./ChangePasswordScreen";
import ChangeNameComponent from "./ChangeNameComponent";

const EditProfileScreen = () => {
  const [userDetails, setUserDetails] = useState();
  const [uploadImage, setUploadImage] = useState("");
  const [previewImage, setPreviewImage] = useState("");
  const [currentStateOfEditScreen, setCurrentStateOfEditScreen] =
    useState("editScreen");
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const accessToken = localStorage.getItem("accesstoken");
  let config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const SaveImage = (file) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("", file);
    axios
      .post(`${APIURI}/web-user/uploadProfilePicture`, formData, config)
      .then((res) => {
        console.log(res);
        localStorage.setItem("Updated_Profile_Pic", res.data.data);
        setTimeout(() => {
          // history.push("/profile");
          window.location.href = "/profile";
          setIsLoading(false);
        }, 500);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getUser = async () => {
    await axios
      .get(`${APIURI}/web-user/getUser`, config)
      .then((res) => {
        console.log(res);
        setUserDetails(res.data.userData);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        history.push("/auth");
      });
  };

  useEffect(() => {
    getUser();
  }, []);

  if (isLoading) return <Loader />;

  return (
    <div className="profile__main__container">
      <input
        type="file"
        name=""
        id="upload_profile_pic"
        accept="image/*"
        style={{ display: "none" }}
        onChange={(e) => {
          if (e.target.files[0]) {
            SaveImage(e.target.files[0])
            setUploadImage(e.target.files[0]);
            setPreviewImage(URL.createObjectURL(e.target.files[0]));
          }
        }}
      />
      <section className="back__edit__section">
        <ArrowBackIosIcon
          onClick={() => {
            if (currentStateOfEditScreen !== "editScreen") {
              setCurrentStateOfEditScreen("editScreen");
            } else {
              // history.push("/profile");
              window.location.href = "/profile";
            }
          }}
        />
      </section>
      <section className="edit_profile_pic_section">
        <span> EDIT PROFILE </span>
        <div
          className="Picture__Main"
          onClick={() => {
            document.getElementById("upload_profile_pic").click();
          }}
        >
          {previewImage ? (
            <img
              src={previewImage}
              alt=""
              srcSet=""
              style={{
                height: "130px",
                width: "130px",
                borderRadius: "50%",
                objectFit: "cover",
              }}
            />
          ) : userDetails && userDetails.profilePicLocation ? (
            <img
              src={userDetails.profilePicLocation}
              alt=""
              srcSet=""
              style={{
                height: "130px",
                width: "130px",
                borderRadius: "50%",
                objectFit: "cover",
              }}
            />
          ) : (
            <img
              src="/assets/ProfilePicIcon_Gray.svg"
              alt="profile_icon"
              srcSet=""
              className="default__img"
            />
          )}
          <img
            src="/assets/ProfilePicEditIcon.svg"
            alt="edit_pencel"
            srcSet=""
            className="edit__icon"
          />
        </div>
        <small>EDIT PICTURE</small>
      </section>
      {currentStateOfEditScreen === "editScreen" ? (
        <section className="edit__profile__actions__section">
          <button
            onClick={() => {
              setCurrentStateOfEditScreen("editPassword");
            }}
          >
            CHANGE PASSWORD
          </button>
          <button
            onClick={() => {
              setCurrentStateOfEditScreen("editName");
            }}
          >
            CHANGE NAME
          </button>
          {/* {previewImage && (
            <button onClick={() => SaveImage()}>SAVE PICTUE</button>
          )} */}
        </section>
      ) : currentStateOfEditScreen === "editPassword" ? (
        <ChangePasswordScreen uploadImage={uploadImage && uploadImage} />
      ) : (
        <ChangeNameComponent
          name={userDetails && userDetails.name}
          uploadImage={uploadImage && uploadImage}
        />
      )}

      {currentStateOfEditScreen === "editScreen" && (
        <section className="log__out__btn__section">
          <button
            onClick={() => {
              localStorage.clear();
              history.push("/auth");
            }}
          >
            LOG OUT
          </button>
        </section>
      )}
    </div>
  );
};

export default EditProfileScreen;

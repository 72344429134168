/* eslint-disable react/jsx-no-target-blank */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import axios from "axios";
import React, { useRef, useState } from "react";
import ReactStars from "react-rating-stars-component";
import { useHistory, withRouter } from "react-router";
import Swal from "sweetalert2";
import { APIURI } from "../config";
import CancelIcon from "@material-ui/icons/Cancel";
import createMarkup from "../utils/CreateMarkup";
import Loader from "../utils/Loader";
import Video from "../utils/Videos/Video";
import Audio from "../utils/Audios/Audio";
import { useEffect } from "react";
import CaptureImages from "./CaptureImages";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import "./congratsforpods.css";

function CongratsForPods({
  sceneDetailsFromPods,
  isSomeThingUpdated,
  isFirstSceneAvailable,
  sceneID,
  frames,
}) {
  const game_id = useParams();
  let history = useHistory();
  //   const [frames, setFrames] = useState([]);
  const [btnLoad, setBtnLoad] = useState(false);
  const [podAccessCode, setPodAccessCode] = useState("");
  const [incorrectCodeError, setIncorrectCodeError] = useState("");
  const inputRefAccessCode = useRef(null);

  const accessToken = localStorage.getItem("accesstoken");
  const accessCode = localStorage.getItem("accessCode");
  let config;
  if (accessCode) {
    config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        accesscode: accessCode,
      },
    };
  } else {
    config = {
      headers: { Authorization: `Bearer ${accessToken}` },
    };
  }

  const handleValidateCode = () => {
    setIncorrectCodeError("");
    setBtnLoad(true);
    axios
      .post(
        `${APIURI}/web-pods/validatePod/${game_id.gameID}`,
        { accessCode: podAccessCode },
        config
      )
      .then((res) => {
        console.log(res);
        inputRefAccessCode.current.blur();
        // window.scrollTo(0, 0);
        window.scrollTo({ top: 0, behavior: "smooth" });
        setTimeout(() => {
          isSomeThingUpdated();
        }, 500);
        // setBtnLoad(false);
        // window.location.reload();
        //   setIsAccessCodeRequired(false);
        // isSomeThingUpdated();
      })
      .catch((err) => {
        console.log(err);
        setBtnLoad(false);
        setIncorrectCodeError("Invalid code. Codes are case sensitive.");
      });
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  const [open, setOpen] = useState(false);
  const [sceneDetails, setSceneDetails] = useState([]);
  const [SceneDescription, setSceneDescription] = useState([]);
  const [showLeaderBoardBtn, setShowLeaderBoardBtn] = useState(false);

  const [review, setReview] = useState("");
  const [rating, setRating] = useState(0.5);
  const [load, setLoad] = useState(false);
  const [isLast, setIsLast] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [facebook, setFacebook] = useState("");
  const [twitter, setTwitter] = useState("");
  const [instagram, setInstagram] = useState("");
  const [tiktok, setTiktok] = useState("");
  const [buttonsElements, setButtonsElements] = useState([]);

  const [togglePopover, setTooglePopover] = useState(false);
  const [togglePopoverMessage, setTooglePopoverMessage] = useState([]);
  const [togglePopoverArr, setTooglePopoverArr] = useState([]);

  // if (document.querySelector("a")) {
  //   const element = document.querySelector("a").href.includes("mailto:");
  //   if (element) {
  //     if (isLast) {
  //       document.querySelector("a").classList.add("style--for--mailto--last");
  //     } else {
  //       document.querySelector("a").classList.add("style--for--mailto");
  //     }
  //     console.log("mailto: element", document.querySelector("a"));
  //   }
  // }

  const renderDesc = (desc) => {
    if (desc.includes("#cameraSection")) {
      return (
        <>
          <div
            className="desc-Text"
            dangerouslySetInnerHTML={createMarkup(
              desc.split("#cameraSection")[0]
            )}
          />
          <div className="desc-Text">
            <CaptureImages
              color={isLast ? "#b2b2b2" : "#000"}
              frames={frames}
            />
          </div>
          {desc.split("#cameraSection")[1] ? (
            <div
              className="desc-Text"
              dangerouslySetInnerHTML={createMarkup(
                `<p>${desc.split("#cameraSection")[1]}`
              )}
            />
          ) : (
            ""
          )}
        </>
      );
    } else {
      return (
        <div
          className="desc-Text"
          dangerouslySetInnerHTML={createMarkup(desc)}
        />
      );
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  async function handleTooltip() {
    let elements = document.getElementsByClassName("tooltip");
    let arr = await togglePopoverArr;
    let myFunction1 = function (e, i, acID) {
      console.log("Handle Tooltip Func");
      e.preventDefault();
      setTooglePopover(true);
      console.log(e, i, arr[0][acID]);
      setTooglePopoverMessage(arr[0][acID]);
    };

    for (let i = 0; i < elements.length; i++) {
      let actionBtn = elements[i];
      let actionBtnId = actionBtn.id.split("-");
      console.log(actionBtnId[1]);
      actionBtn.addEventListener(
        "mousedown",
        (e) => myFunction1(e, i, actionBtnId[1]),
        false
      );
    }
  }
  const submitFeedback = (e) => {
    setLoad(true);
    e.preventDefault();
    const data = {
      scene_id: sceneID,
      stars: rating,
      review: review,
      user_id: "placeholder",
    };
    axios
      .post(`${APIURI}/web-rating/save`, data, config)
      .then((res) => {
        //     Toast.fire({
        //         icon: 'success',
        //         title: 'Thank you for your feedback!'
        //    })
        setOpen(false);
        setLoad(false);
      })
      .catch((err) => {
        //     Toast.fire({
        //         icon: 'error',
        //         title: 'Try again later!'
        //    })
        setLoad(false);
      });
  };
  const StarRatings = {
    size: 40,
    isHalf: true,
    count: 5,
    value: rating,
    onChange: (newValue) => {
      setRating(newValue);
    },
  };
  function UpdateMailToLinks() {
    // if(document.querySelector("a")){
    //   const element = document.querySelector("a").href.includes("mailto:")
    //   if(element){
    //     document.querySelector("a").classList.add("style--for--mailto");
    //     console.log("mailto: element",document.querySelector("a"))
    //     alert(document.querySelector("a"))
    //   }
    //   }
    let allA = document.getElementsByTagName("A");
    // console.log("mailto: llink",allA)
    for (let i = 0, len = allA.length; i < len; i++) {
      console.log("mailto: llink for loop", allA[i]);
      if (allA[i].href.includes("mailto:")) {
        if (isLast) {
          allA[i].className = "style--for--mailto--last";
        } else {
          allA[i].className = "style--for--mailto";
        }
        //  console.log("mailto: llink",allA[i])
      }
    }
    setLoad(false);
  }

  useEffect(() => {
    UpdateMailToLinks();
  }, [load]);
  function ContentTypeComponent(data) {
    // const data = props.content;
    if (data.key === "Text") {
      let desc = data.value;
      console.log("Scene Details", sceneDetails);
      //   sceneDetails[0].inputFields.map((te) => {
      //     for (const [key, value] of Object.entries(te)) {
      //       console.log(key, value);
      //       desc = desc.replaceAll("#" + key, value);
      //     }
      //   });
      sceneDetails[0].scene_popup.map((te, i) => {
        if (te.icon) {
          desc = desc.replaceAll(
            te.identifier,
            `<span class="tooltip" id='tooltip-${i}'><img src="${te.icon}" alt="PopupIcon" /><span>${te.title}</span></span>`
          );
        } else {
          desc = desc.replaceAll(
            te.identifier,
            `<span class="tooltip" id='tooltip-${i}'><img src ="/assets/PopupIcon.svg" alt="PopupIcon" /><span>${te.title}</span></span>`
          );
        }
        handleTooltip();
      });
      return renderDesc(desc);
    } else if (data.key === "Audio") {
      return (
        // <audio
        //   style={{ width: "100%", marginBottom: "1em" }}
        //   controls
        //   controlsList="nofullscreen nodownload noremoteplayback noplaybackrate"
        //   src={data.value}
        // />
        <div className="media-container">
          <div style={{ marginTop: "-1.5em", marginBottom: "-1.5em" }}>
            <Audio content={data.value} />
          </div>
        </div>
      );
    } else if (data.key === "Image") {
      return (
        <div className="media-container">
          <img
            style={{
              width: "100%",
              // maxHeight: "40vh",
              height: "100%",
            }}
            src={data.value}
            alt="SceneFeaturedImage"
          />
        </div>
      );
    } else {
      return (
        <div className="media-container">
          <Video content={data.value} />
        </div>
      );
    }
  }
  React.useEffect(() => {
    window.scrollTo(0, 0);
    LoadScene();
  }, []);
  const LoadScene = async () => {
    setLoad(true);
    if (sceneDetailsFromPods[0]) {
      setIsLast(false);
      let arr = [];
      arr.push(sceneDetailsFromPods[0]);
      //   setFrames(sceneDetailsFromPods[0].Frames);
      setSceneDetails(arr);
      setSceneDescription(sceneDetailsFromPods[0].scene_description);
      // console.log(sceneDetailsFromPods[0].isLast);
      let successMSG = sceneDetailsFromPods[0].success_message;

      // sceneDetailsFromPods[0].inputFields.map((te) => {
      //   for (const [key, value] of Object.entries(te)) {
      //     console.log(key, value);
      //     successMSG = successMSG.replaceAll("#" + key, value);
      //   }
      // });
      if (sceneDetailsFromPods[0].scene_popup && successMSG) {
        sceneDetailsFromPods[0].scene_popup.map((te, i) => {
          if (te.icon) {
            successMSG = successMSG.replaceAll(
              te.identifier,
              `<span class="tooltip" id='tooltip-${i}'><img src="${te.icon}" alt="PopupIcon" /><span>${te.title}</span></span>`
            );
          } else {
            successMSG = successMSG.replaceAll(
              te.identifier,
              `<span class="tooltip" id='tooltip-${i}'><img src ="/assets/PopupIcon.svg" alt="PopupIcon" /><span>${te.title}</span></span>`
            );
          }

          // handleTooltip();
        });
      }

      setSuccessMessage(successMSG);
      let arrPop = togglePopoverArr;
      arrPop.push(
        sceneDetailsFromPods[0].scene_popup.map((te) => te.description)
      );
      console.log(arrPop);
      setTooglePopoverArr(arrPop);
      setTimeout(() => {
        handleTooltip();
      }, 2000);

      setTimeout(() => {
        UpdateMailToLinks();
      }, 500);
      //   });
      await axios.get(`${APIURI}/web-lastScreen/view`, config).then((res) => {
        setLoad(true);
        console.log(res.data.message);
        setFacebook(res.data.message[0].facebook);
        setTwitter(res.data.message[0].twitter);
        setInstagram(res.data.message[0].instagram);
        setTiktok(res.data.message[0].tiktok);
        setButtonsElements(res.data.message[0].message);
        setLoad(false);
      });
    } else {
      setIsLast(false);
    }
  };

  if (load) {
    return <Loader />;
  }
  const handleClosePopover = () => {
    setTooglePopover(false);
    // window.location.reload();
  };
  const LastScreen = () => (
    <div className="congratssplash accesscode_for_pods">
      <div className="close" style={{ visibility: "hidden" }}>
        <button onClick={(e) => history.goBack()}>
          <span>[x]</span>Close
        </button>
      </div>
      <div style={{ textAlign: "center", paddingBottom: "0.5em" }}>
        <img
          src="/assets/Lock@3x.png"
          alt=""
          style={{ width: "70px", height: "120px" }}
        />
        {/* {isLast?:<img src="/assets/KeyTransparent.svg" alt="" style={{ width: "100%" }} />} */}
      </div>
      <div className="container-text solution-congrats-black">
        {/* <h2>YOU DID IT! </h2> */}
        {SceneDescription.map((data) => {
          if (data.type === "success_description") {
            return (
              <div key={data._id}>
                {/* <ContentTypeComponent content={data} /> */}
                {ContentTypeComponent(data)}
              </div>
            );
          }
        })}
        {/* <div dangerouslySetInnerHTML={createMarkup(successMessage)} /> */}
        {/* <button onClick={(e) => setTooglePopover(true)}>Popover butoon</button> */}
      </div>
      <div className="container-rate">
        {/* <a className="btn-last" onClick={handleClickOpen}>
            RATE US
          </a> */}
        {buttonsElements.map((btn) => {
          return (
            <a className="btn-last" key={btn._id} href={btn.placeholder}>
              {btn.label}
              {console.log("buttons-element", buttonsElements)}
            </a>
          );
        })}{" "}
      </div>
      <Dialog
        open={togglePopover}
        onClose={handleClosePopover}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="PopOver"
      >
        {/* <DialogTitle id="alert-dialog-title">Message</DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div dangerouslySetInnerHTML={createMarkup(togglePopoverMessage)} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClosePopover}
            className="dialog-btn-dismiss"
            style={{ marginBottom: "2.2rem" }}
            autoFocus
            variant="contained"
          >
            Okay
          </Button>
          {/* <Camera onTakePhoto = { (dataUri) => { handleTakePhoto(dataUri); } }/> */}
        </DialogActions>
      </Dialog>
      <div className="container-social">
        {facebook !== "" ? (
          <a href={`${facebook}`} target="_blank">
            <i class="fa fa-facebook-official" aria-hidden="true"></i>
          </a>
        ) : (
          ""
        )}
        {twitter !== "" ? (
          <a href={`${twitter}`} target="_blank">
            <i class="fa fa-twitter-square" aria-hidden="true"></i>
          </a>
        ) : (
          ""
        )}
        {instagram !== "" ? (
          <a href={`${instagram}`} target="_blank">
            <i class="fa fa-instagram" aria-hidden="true"></i>
          </a>
        ) : (
          ""
        )}
        {tiktok !== "" ? (
          <a href={`${tiktok}`} target="_blank">
            <i class="fa fa-music" aria-hidden="true"></i>
          </a>
        ) : (
          ""
        )}
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        className="feedbackDialog"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Feedback"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            How was your experience playing Escapely?
          </DialogContentText>
          {/* <StarRatings
                      rating={rating}
                      starRatedColor="blue"
                      changeRating={changeRating}
                      numberOfStars={6}
                      name='rating'
                      /> */}
          <ReactStars className="star-rating" {...StarRatings} /> <br />
          <TextField
            id="outlined-basic"
            label="Feedback"
            type="textarea"
            variant="outlined"
            fullWidth="true"
            onChange={(e) => setReview(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            className="cancelButton"
            onClick={handleClose}
            color="warning"
            variant="contained"
          >
            <CancelIcon />
          </Button>
          <Button
            onClick={submitFeedback}
            color="primary"
            autoFocus
            variant="contained"
            className="submitFeedback"
          >
            Submit Feedback
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
  const IsNotLast = () => (
    <div className="congratssplash-white accesscode_for_pods">
      <div className="close" style={{ visibility: "hidden" }}>
        <button onClick={(e) => history.goBack()}>
          <span>[x]</span>Close
        </button>
      </div>
      <div className="container-img">
        <img
          src="/assets/KeyTransparent.svg"
          alt=""
          style={{ width: "100%" }}
        />
      </div>
      <div className="container-text solution-congrats">
        {/* <h2>YOU DID IT! </h2> */}
        {/* <div dangerouslySetInnerHTML={createMarkup(successMessage)} /> */}
        {SceneDescription.map((data) => {
          if (data.type === "success_description") {
            return (
              <div key={data._id}>
                {/* <ContentTypeComponent content={data} /> */}
                {ContentTypeComponent(data)}
              </div>
            );
          }
        })}
      </div>
      <div className="Solution" style={{ marginBottom: "3em" }}>
        <div className="container-sol">
          <div className="container-code">
            <div className="sol-enter" style={{ paddingBottom: "0" }}>
              <label>Access Code</label>
              <input
                type="text"
                Value={podAccessCode}
                placeholder="Enter Access Code"
                autoCorrect="off" // Turn off autocorrect
                autoCapitalize="none" // Turn off autocapitalization
                ref={inputRefAccessCode}
                onChange={(e) => {
                  setPodAccessCode(e.target.value);
                  setIncorrectCodeError("");
                }}
                // onFocus={(e) => handleFocus(index, e, key._id)}
              />
              <p />
            </div>
            <p
              style={{
                color: "#dc3545",
                margin: "-0.5em auto",
                fontWeight: "700",
              }}
            >
              {incorrectCodeError ? incorrectCodeError : <p />}
            </p>
            <div
              className="submit--btn--cong--pod"
              style={{ marginTop: "2em" }}
            >
              <Button
                className="submit--btn"
                variant="contained"
                disabled={btnLoad}
                style={{ opacity: `${btnLoad ? "0.6" : "1"}` }}
                onClick={() => {
                  handleValidateCode();
                }}
              >
                SUBMIT
              </Button>
              {/* <p className="save--photo--p">
                Enter access code to enter in pod.
              </p> */}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="container-social-white">
        {facebook !== "" ? (
          <a href={`${facebook}`} target="_blank">
            <i class="fa fa-facebook-official" aria-hidden="true"></i>
          </a>
        ) : (
          ""
        )}
        {twitter !== "" ? (
          <a href={`${twitter}`} target="_blank">
            <i class="fa fa-twitter-square" aria-hidden="true"></i>
          </a>
        ) : (
          ""
        )}
        {instagram !== "" ? (
          <a href={`${instagram}`} target="_blank">
            <i class="fa fa-instagram" aria-hidden="true"></i>
          </a>
        ) : (
          ""
        )}
        {tiktok !== "" ? (
          <a href={`${tiktok}`} target="_blank">
            <i class="fa fa-music" aria-hidden="true"></i>
          </a>
        ) : (
          ""
        )}
      </div> */}
      <Dialog
        open={togglePopover}
        onClose={handleClosePopover}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="PopOver"
      >
        {/* <DialogTitle id="alert-dialog-title">Message</DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div dangerouslySetInnerHTML={createMarkup(togglePopoverMessage)} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClosePopover}
            className="dialog-btn-dismiss"
            style={{ marginBottom: "2.2rem" }}
            autoFocus
            variant="contained"
          >
            Okay
          </Button>
          {/* <Camera onTakePhoto = { (dataUri) => { handleTakePhoto(dataUri); } }/> */}
        </DialogActions>
      </Dialog>
    </div>
  );
  if (isLast) {
    return LastScreen();
    // return <LastScreen />;
  } else {
    return IsNotLast();
    // return <IsNotLast />;
  }
}
export default withRouter(CongratsForPods);

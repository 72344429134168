/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from "axios";
import React, { useRef } from "react";
import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { APIURI } from "../config";
// import LogoutComponent from "./Logout";
import createMarkup from "../utils/CreateMarkup";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Box, Button, Input } from "@material-ui/core";
import HintComponent from "../components/HintComponent";
import { withRouter } from "react-router-dom";
import Loader from "../utils/Loader";

function TutorialStory(props) {
  const [tutorials, setTutorials] = useState([]);
  const gameID = props.match.params.gameID;
  const fifthRef = useRef(null);
  const location = useLocation();
  const lastContainerRef = useRef(null);

  const history = useHistory();
  const [isHints, setIsHints] = useState(false);
  const [load, setLoad] = useState(false);
  const accessToken = localStorage.getItem("accesstoken");
  const [tutMessage, settutMessage] = useState("");
  const [secondMessage, setsecondMessage] = useState("");
  const [fifthMessage, setfifthMessage] = useState("");
  const [isHintPulse, setIsHintPulse] = useState(false);
  const [isHintGrey, setIsHintGrey] = useState(false);
  const [lastTutMessage, setLastTutMessage] = useState("");
  const [tutCount, setTutCount] = useState(0);
  const [containerActive, setContainerActive] = useState("");
  const accessCode = localStorage.getItem("accessCode");
  let config;
  if (accessCode) {
    config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        accesscode: accessCode,
      },
    };
  } else {
    config = {
      headers: { Authorization: `Bearer ${accessToken}` },
    };
  }

  const handleLogout = (e) => {
    e.preventDefault();
    localStorage.removeItem("accesstoken");
    localStorage.clear();
    window.location.href = "/auth";
  };

  const BottomNavBar = () => {
    console.log(localStorage.getItem("isLoginRequired"));
    if (localStorage.getItem("isLoginRequired") !== "false") {
      return (
        <div className="fixed__div">
          <div className="footer__container">
            <div
              onClick={() => {
                localStorage.setItem("backLink", window.location.href);
                setTimeout(() => {
                  window.location.href = "/profile";
                }, 500);
              }}
            >
              <img src={"/assets/AccountIcon_white.svg"} alt="" srcSet="" />
              {/* <img src="/assets/ProfilePicIcon.svg" alt="" srcSet="" /> */}
              <span>ACCOUNT</span>
            </div>
            <div
              onClick={() =>
                window.open("https://escapely.com/shop/", "_blank")
              }
            >
              <img src="/assets/ShopIcon_white.svg" alt="" srcSet="" />
              {/* <img src="/assets/ShopIcon_Yellow-01.svg" alt="" srcSet="" /> */}
              <span>SHOP</span>
            </div>
            <div
              onClick={() => {
                if (isHintPulse) {
                  setIsHints(true);
                  setIsHintPulse(false);
                  setIsHintGrey(true);
                }
              }}
            >
              <img
                src={
                  isHintGrey
                    ? "/assets/HintsIcon_Gray.svg"
                    : "/assets/HintsIcon_white.svg"
                }
                alt=""
                srcSet=""
                className={
                  isHintPulse
                    ? "hint-pulse hint__img__footer"
                    : "hint__img__footer"
                }
              />
              {/* <img
                  src="/assets/HintsIcon_Gray.svg"
                  alt=""
                  srcSet=""
                  className="hint__img__footer"
                /> */}
              {/* <img
                  src="/assets/HintsIcon_Yellow-01.svg"
                  alt=""
                  srcSet=""
                  className="hint__img__footer"
                /> */}
              <span>HINTS</span>
            </div>
            <div>
              <img src="/assets/TutorialIcon_Yellow-01.svg" alt="" srcSet="" />
              {/* <img src="/assets/TutorialIcon_Yellow-01.svg" alt="" srcSet="" /> */}
              <span>TUTORIAL</span>
            </div>
            <div onClick={(e) => handleLogout(e)}>
              <img src="/assets/LogoutIcon_white.svg" alt="" srcSet="" />
              {/* <img src="/assets/LogoutIcon_Yellow-01.svg" alt="" srcSet="" /> */}
              <span>LOGOUT</span>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="fixed__div">
          <div className="footer__container__loginNotRequired">
            <div
              onClick={() => {
                window.open("https://escapely.com/shop/", "_blank");
              }}
            >
              <img src="/assets/ShopIcon_white.svg" alt="" srcSet="" />
              {/* <img src="/assets/ShopIcon_Yellow-01.svg" alt="" srcSet="" /> */}
              <span>SHOP</span>
            </div>
            <div
              onClick={() => {
                if (isHintPulse) {
                  setIsHints(true);
                  setIsHintPulse(false);
                  setIsHintGrey(true);
                }
              }}
            >
              <img
                src={
                  isHintGrey
                    ? "/assets/HintsIcon_Gray.svg"
                    : "/assets/HintsIcon_white.svg"
                }
                alt=""
                srcSet=""
                className={
                  isHintPulse
                    ? "hint-pulse hint__img__footer"
                    : "hint__img__footer"
                }
              />
              {/* <img
                  src="/assets/HintsIcon_Gray.svg"
                  alt=""
                  srcSet=""
                  className="hint__img__footer"
                /> */}
              {/* <img
                  src="/assets/HintsIcon_Yellow-01.svg"
                  alt=""
                  srcSet=""
                  className="hint__img__footer"
                /> */}
              <span>HINT</span>
            </div>
            <div>
              <img src="/assets/TutorialIcon_Yellow-01.svg" alt="" srcSet="" />
              {/* <img src="/assets/TutorialIcon_Yellow-01.svg" alt="" srcSet="" /> */}
              <span>TUTORIAL</span>
            </div>
          </div>
        </div>
      );
    }
  };

  const SolutionBoxComponent = () => (
    <React.Fragment>
      <Box className="tutorial-sol">
        <label>Enter Code</label>
        <input type="text" disabled />
      </Box>
      <Box className="box-tut">
        <Button className="btn-tut">SUBMIT</Button>
        {/* <Button
                  className="btn-tut-hint"
                  onClick={(e) => setIsHints(true)}
                >
                  HINT
                </Button> */}
        {/* <div className="Solution" style={{ width: "100%" }}>
          <div className="container-sol" style={{ marginTop: "1.3em" }}>
            <div className="cta" style={{ padding: 0, marginTop: "2.5em" }}>
              <a
                onClick={(e) => {
                  setIsHints(true);
                  setIsHintPulse(false);
                  setIsHintGrey(true);
                }}
              >
                <img
                  className="solution-icon hint-pulse"
                  // className="animate__animated animate__pulse animate__infinite animated-hint solution-icon"
                  src="/assets/Help@3x.png"
                  alt=""
                />{" "}
                Hint
              </a>
              <a>
                <img
                  className="solution-icon"
                  src="/assets/Settings@3x.png"
                  alt=""
                />{" "}
                Tutorial
              </a>
            </div>
          </div>
        </div> */}
        <button className="tut-skip" onClick={(e) => history.goBack()}>
          Skip Tutorial
        </button>
      </Box>
    </React.Fragment>
  );

  const SolutionBoxInputComponent = (props) => (
    <React.Fragment>
      <Box className="tutorial-sol">
        <label>Enter Code</label>
        <input type="text" disabled={props.isDisabled} />
      </Box>
      <Box className="box-tut">
        <Button
          className="submit-pulse btn-tut"
          // className="btn-tut animate__animated animate__pulse animate__infinite"
          onClick={(e) => setContainerActive("Last")}
        >
          SUBMIT
        </Button>
        <div className="Solution" style={{ width: "100%" }}>
          {/* <hr /> */}
          <div className="container-sol" style={{ marginTop: "1.5em" }}>
            <div className="cta" style={{ padding: 0 }}>
              <a>
                <img
                  className="solution-icon"
                  src="/assets/Help@3x.png"
                  alt=""
                />{" "}
                Hint
              </a>
              <a>
                <img
                  className="solution-icon"
                  src="/assets/Settings@3x.png"
                  alt=""
                />{" "}
                Tutorial
              </a>
            </div>
          </div>
        </div>
        <button className="tut-skip" onClick={(e) => history.goBack()}>
          Skip Tutorial
        </button>
      </Box>
    </React.Fragment>
  );

  const SubmitActiveContainer = () => (
    <React.Fragment>
      <div className="tutorial-header">
        <h1 className="tutorial-title">Tutorial</h1>
      </div>
      <hr className="hr-tut" />
      <div className="fifth-container" ref={fifthRef}>
        <Box>
          <div
            className="tut-msg-container"
            dangerouslySetInnerHTML={createMarkup(secondMessage)}
          />
        </Box>
        <SolutionBoxInputComponent isDisabled={false} />
      </div>
    </React.Fragment>
  );

  const HintsActiveContainer = () => (
    <React.Fragment>
      <div className="tutorial-header">
        <h1 className="tutorial-title">Tutorial</h1>
      </div>
      <hr className="hr-tut" />
      <div className="first-container">
        <Box>
          <div
            className="tut-msg-container"
            dangerouslySetInnerHTML={createMarkup(tutMessage)}
          />
        </Box>
        <SolutionBoxComponent />
      </div>
    </React.Fragment>
  );

  const FinalActiveContainer = () => (
    <div className="last-container" ref={lastContainerRef}>
      <div className="tut-image">
        <img src="/assets/KeyTransparent.svg" alt="Tutorial" />
      </div>
      <div
        className="tut-msg-container"
        dangerouslySetInnerHTML={createMarkup(lastTutMessage)}
      />
      <div className="tut-footer">
        {/* <h4>Open the next envelope.</h4> */}
        {/* <div className="container-fluid">
          <p>
            Let's order <br /> a bottle for <br /> the table
          </p>
        </div> */}
        <button
          className="tut-skip animate__animated animate__pulse animate__infinite"
          onClick={(e) => history.goBack()}
        >
          End Tutorial
        </button>
      </div>
    </div>
  );

  // const ExtraContainer = () => (
  //    {tutorials.slice(3, tutCount).map((msg, index) => {
  //           return (
  //             <div
  //               key={msg._id}
  //               dangerouslySetInnerHTML={createMarkup(msg.message)}
  //             />
  //           );
  //         })}
  // );

  useEffect(() => {
    loadTutorials();
  }, []);

  const loadTutorials = async () => {
    setLoad(true);
    await axios
      .get(`${APIURI}/web-tutorial/view/${gameID}`, config)
      .then((res) => {
        console.log(res.data.message);
        setTutorials(res.data.message.tutorial_message);
        settutMessage(res.data.message.tutorial_message[0].message);
        setsecondMessage(res.data.message.tutorial_message[1].message);
        setfifthMessage(res.data.message.tutorial_message[2].message);
        let count = res.data.message.tutorial_message.length - 1;
        setTutCount(count);
        setLastTutMessage(res.data.message.tutorial_message[count].message);
        setLoad(false);
      })
      .catch((err) => {
        setLoad(false);
        console.error(err);
      });
  };

  if (load) {
    return <Loader />;
  }

  const executeNext = (e) => {
    setIsHints(false);
    setContainerActive("Submit");
  };

  const ActiveContainerSelector = () => {
    if (containerActive === "Last") {
      setIsHintPulse(false);
      setIsHintGrey(true);
      return <FinalActiveContainer />;
    } else if (containerActive === "Submit") {
      setIsHintPulse(false);
      setIsHintGrey(true);
      return <SubmitActiveContainer />;
    } else {
      setIsHintPulse(true);
      setIsHintGrey(false);
      return <HintsActiveContainer />;
    }
  };

  return (
    <div className="Hints" style={{ paddingBottom: "100px" }}>
      {isHints ? (
        <React.Fragment>
          <table
            style={{
              backgroundColor: "#FFFB00",
              width: "100%",
              paddingTop: "3.45rem",
            }}
          >
            <tr>
              <td style={{ paddingLeft: "22px" }}>
                <button
                  onClick={(e) => executeNext(e)}
                  className="back_btn_hint back-pulse"
                >
                  <ArrowBackIcon />
                </button>
              </td>
              <td style={{ paddingRight: "22px" }}>
                <h2 className="hints_h2_td">Hints</h2>
              </td>
              <td>
                <button style={{ visibility: "hidden" }}>
                  <ArrowBackIcon />
                </button>
              </td>
            </tr>
          </table>
          {/* <div className="Hints-header">
            <h2>Hints</h2>
            <button onClick={(e) => executeNext(e)} className="back-pulse">
              <ArrowBackIcon />
            </button>{" "}
          </div> */}
          <div>
            <HintComponent />
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="active-container">
            <ActiveContainerSelector />
          </div>
          <BottomNavBar />
        </React.Fragment>
      )}

      {/* <LogoutComponent /> */}
    </div>
  );
}
export default withRouter(TutorialStory);

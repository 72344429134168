import { Player, BigPlayButton, ControlBar } from "video-react";
import React, { Suspense, useEffect } from "react";
import { useState } from "react";

export default function Video(props) {
  // console.log(BigPlayButton)
  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }
  }
  return (
    <Suspense fallback={"Loading ..."}>
      {console.log(getMobileOperatingSystem())}
          {getMobileOperatingSystem() === "iOS" ? (
            <Player preload="metadata">
              <source src={props.content + "#t=0.05"} />
              <ControlBar disableCompletely />
              <BigPlayButton position="center" />
            </Player>
          ) : (
            <Player preload="metadata">
              <source src={props.content + "#t=0.05"} />
              <BigPlayButton position="center" />
            </Player>
          )}
    </Suspense>
  );
}
